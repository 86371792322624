import { colors } from "vuetify/lib";

const state = {
  status: [
    {
      text: "In-Progress",
      value: "in progress",
      color: colors.blue.base,
    },
    {
      text: "Complete",
      value: "complete",
      color: colors.green.base,
    },
    {
      text: "Cancelled",
      value: "cancelled",
      color: colors.red.base,
    },
  ],
  paymentTerms: [
    { text: "COD", value: "cod", color: "green", due: 0 },
    { text: "Net 7", value: "net 7", color: "black", due: 7 },
    { text: "Net 10", value: "net 10", color: "black", due: 10 },
    { text: "Net 14", value: "net 14", color: "orange", due: 14 },
    { text: "Net 30", value: "net 30", color: "brown", due: 30 },
    { text: "Net 45", value: "net 45", color: "brown", due: 45 },
    { text: "Net 60", value: "net 60", color: "brown", due: 60 },
    { text: "Net 90", value: "net 90", color: "brown", due: 90 },
    {
      text: "Consignment",
      value: "consignment",
      color: "brown",
      due: 30,
    },
    { text: "[Not Set]", value: null, color: "black", due: -1 },
  ],
  paymentTypes: [
    { text: "Cash", value: "cash", salesOrders: true, general: true },
    { text: "Check", value: "check", salesOrders: true, general: true },
    { text: "ACH", value: "ach", salesOrders: true, general: true },
    {
      text: "Credit Card",
      value: "creditCard",
      salesOrders: true,
      general: true,
    },
    // {
    //   text: "Overpayment Credit",
    //   value: "credit",
    //   salesOrders: true,
    //   general: false,
    // },
    // { text: "Trade", value: "trade", salesOrders: true, general: true },
    { text: "Wire", value: "wire", salesOrders: true, general: true },
    { text: "Cashier", value: "cashier", salesOrders: true, general: true },
    { text: "Other", value: "other", salesOrders: true, general: true },
    // {
    //   text: "Credits",
    //   value: "promotionCredit",
    //   salesOrders: true,
    //   general: false,
    // },
    {
      text: "Charge Off",
      value: "chargeOff",
      salesOrders: true,
      general: false,
    },
  ],
};

const actions = {};

const mutations = {};

const getters = {
  getPaymentTerms(state) {
    return state.paymentTerms;
  },
  getPaymentTypes(state) {
    return state.paymentTypes;
  },
  getInvoiceStatus(state) {
    return state.status;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
