export default {
  data() {
    return {
      // refresh variables
      refreshing: false,
      registration: null,
      updateExists: false,
      isDownloadingUpdate: false,
      willRefresh: false,
    };
  },

  created() {
    // Listen for our custom event from the SW registration
    // console.log("Creating service worker");
    document.addEventListener("swUpdated", this.updateAvailable, {
      once: true,
    });
    document.addEventListener("swUpdateCached", this.updateCached, {
      once: true,
    });
    document.addEventListener("swUpdateFound", this.updateDownloading, {
      once: true,
    });

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });
  },

  methods: {
    updateDownloading(event) {
      this.registration = event.detail;
      this.isDownloadingUpdate = true;
    },
    updateCached(event) {
      this.registration = event.detail;
      this.isDownloadingUpdate = false;
    },
    // Store the SW registration so we can send it a message
    // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
    // To alert the user there is an update they need to refresh for
    updateAvailable(event) {
      this.registration = event.detail;
      this.updateExists = true;
      this.isDownloadingUpdate = false;

      //  Automatically install the update instead of waiting for user to
      //  refresh/install the update themselves

      //  Comment out the following lines to give user the ability to update without
      //  forcing it

      //  Delay the page refresh/update for 2 seconds to give user time to see the update alert
      new Promise((resolve) => setTimeout(resolve, 2000)).then(() =>
        this.refreshApp()
      );
    },

    // Called when the user accepts the update
    refreshApp() {
      this.willRefresh = true;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;
      // send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
      this.updateExists = false;
      this.willRefresh = false;
    },
  },
};
