export default {
  // DEV
  // apiKey: "AIzaSyBBU_kk5xpnb7lgBWOriSlo4dV91NZ0o6A",
  // authDomain: "mytrace-mgmt-test.firebaseapp.com",
  // projectId: "mytrace-mgmt-test",
  // storageBucket: "mytrace-mgmt-test.appspot.com",
  // messagingSenderId: "247210059043",
  // appId: "1:247210059043:web:fda20894193678d85f1896",
  // Prod
  apiKey: "AIzaSyAap8SzRXDfZOro_naNnAv0qe8pwAtUFf8",
  authDomain: "mytrace-corporate.firebaseapp.com",
  projectId: "mytrace-corporate",
  storageBucket: "mytrace-corporate.appspot.com",
  messagingSenderId: "153418225600",
  appId: "1:153418225600:web:2a3ada97f56484c8bada25"
};
