import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VTextField,{attrs:{"label":_vm.label,"clearable":"","background-color":_vm.backgroundColor,"prepend-inner-icon":"mdi-magnify","color":_vm.$vuetify.theme.themes.dark.primary,"disabled":_vm.disableField,"hide-details":""},on:{"click:clear":_vm.clearInput,"input":_vm.handleSearch},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [(_vm.selection && _vm.addressUpdated)?_c(VBtn,{attrs:{"small":"","rounded":"","dark":"","tabindex":"-1"},on:{"click":function($event){return _vm.traverse('reset', 'address')}}},[_c('span',{staticClass:"mr-1"},[_vm._v("undo")]),_c(VIcon,{attrs:{"small":""}},[_vm._v(_vm._s(_vm.$vuetify.icons.values.undo))])],1):_vm._e()]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}}),(_vm.response.searchResults.length > 0 && !_vm.loading.results)?_c(VList,{staticClass:"py-0 transparent",attrs:{"dense":""}},[_vm._l((_vm.response.searchResults),function(result,i){return [(i !== 0)?_c(VDivider,{key:`${i}-divider`}):_vm._e(),_c(VListItem,{key:`${i}-${result.text}`},[_c(VListItemIcon,{staticClass:"mr-4"},[_c(VIcon,{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.$vuetify.icons.values.map)+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-wrap"},[_vm._v(_vm._s(result.text))])],1),_c(VListItemIcon,[_c(VBtn,{style:(`background: ${
              result.selected
                ? _vm.$vuetify.theme.themes.dark.success
                : _vm.$vuetify.theme.themes.dark.review
            }; color: ${_vm.$vuetify.theme.themes.dark.secondary};`),attrs:{"xSmall":"","rounded":""},on:{"click":function($event){return _vm.traverse('select', result)}}},[_c('span',[_vm._v(_vm._s(result.selected ? "selected" : "select"))]),_c(VIcon,{staticClass:"pl-1",attrs:{"xSmall":""}},[_vm._v(_vm._s(result.selected ? _vm.$vuetify.icons.values.submit : "mdi-cursor-default"))])],1)],1)],1)]})],2):_c(VList,{staticClass:"transparent",attrs:{"dense":""}},[_c(VListItem,{key:"noData",staticClass:"d-flex justify-center align-center"},[(_vm.loading.results)?_c('div',{staticClass:"d-flex justify-center"},[_c('div',{staticClass:"d-flex flex-row align-center justify-center"},[_c(VProgressCircular,{attrs:{"width":3,"size":"15","color":_vm.$vuetify.theme.themes.dark.primary,"indeterminate":""}}),_c(VListItemSubtitle,{staticClass:"text--disabled ml-2"},[_vm._v("Loading results...")])],1)]):_c(VListItemContent,[(!_vm.search || _vm.search?.length === 0)?_c(VListItemSubtitle,{staticClass:"text--disabled d-flex justify-center"},[_vm._v("Enter an address to search")]):_c(VListItemSubtitle,{staticClass:"text--disabled d-flex justify-center"},[_vm._v("No results found.")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }