import { colors } from "vuetify/lib";

const state = {
    statuses: [
        {
            text: "Cancelled",
            value: "cancelled",
            desc: "",
            complete: true,
            color: colors.red.base,
        },
        {
            text: "Schedule Demo",
            value: "scheduledemo",
            desc: "",
            complete: false,
            color: colors.amber.base,
        },
        {
            text: "In-Progress",
            value: "inprogress",
            desc: "",
            complete: false,
            color: colors.blue.base,
        },
        {
            text: "Additional Review",
            value: "additionalreview",
            desc: "",
            complete: false,
            color: colors.purple.base,
        },
        {
            text: "Complete",
            value: "complete",
            desc: "",
            complete: true,
            color: colors.green.base,
        },
    ]
};

const actions = {};

const mutations = {};

const getters = {
    getDemoStatus(state) {
        return state.statuses;
    }
};

export default {
  state,
  actions,
  mutations,
  getters,
};
