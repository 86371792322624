import config from "./config";
import { initializeApp, getApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  onSnapshot,
  collection,
  getDocs,
  getDoc,
  query,
  where,
  serverTimestamp,
  writeBatch,
  increment,
  updateDoc,
  arrayUnion,
  arrayRemove,
  doc,
  documentId,
  orderBy,
  startAfter,
  limit,
  getCountFromServer,
  GeoPoint,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytes,
  uploadString,
  getDownloadURL,
  getMetadata,
  getBytes,
  deleteObject,
} from "firebase/storage";
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";
import {
  getRemoteConfig,
  fetchAndActivate,
  getValue,
} from "firebase/remote-config";

initializeApp(config);
const auth = getAuth();
const firestore = getFirestore();
const storage = getStorage();
const functions = getFunctions(getApp());
const remoteConfig = getRemoteConfig();

if (process.env.NODE_ENV === "development") {
  connectFunctionsEmulator(functions, "localhost", 5001);
}

export {
  // Auth
  auth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  // Firestore
  firestore,
  collection,
  onSnapshot,
  doc,
  documentId,
  getDocs,
  getDoc,
  query,
  where,
  writeBatch,
  serverTimestamp,
  increment,
  updateDoc,
  arrayUnion,
  arrayRemove,
  orderBy,
  startAfter,
  limit,
  getCountFromServer,
  GeoPoint,
  // Storage
  storage,
  ref,
  uploadBytes,
  uploadString,
  getDownloadURL,
  getMetadata,
  getBytes,
  deleteObject,
  // Functions,
  functions,
  httpsCallable,
  // Remote Config
  remoteConfig,
  fetchAndActivate,
  getValue,
};
