import { colors } from "vuetify/lib";

const state = {
  status: [
    {
      text: "Cold Lead",
      desc: "No prior contact",
      value: "cold_lead",
      color: colors.grey.base,
      pending: true,
      canSet: true,
    },
    {
      text: "Hot Lead",
      desc: "Met at a tradeshow/event, prior contact",
      value: "hot_lead",
      color: colors.orange.base,
      pending: true,
      canSet: true,
    },
    {
      text: "Intake Pending",
      desc: "Information to schedule a personalized demo needs to be reviewed",
      value: "intake_pending",
      color: colors.purple.base,
      pending: true,
      canSet: false,
    },
    {
      text: "Demo Scheduled",
      desc: "Intake step of demo is complete, demo pending",
      value: "demo_scheduled",
      color: colors.blue.base,
      pending: true,
      canSet: false,
    },
    {
      text: "Active - Current Payments",
      desc: "Active Licensee",
      value: "active",
      color: colors.green.base,
      pending: true,
      active: true,
      canSet: true,
    },
    {
      text: "Active - Delinquent Payments",
      desc: "Licensee has missed 1+ payment",
      value: "delinquent",
      color: colors.red.lighten3,
      active: true,
      canSet: true,
    },
    {
      text: "Inactive - Previously Active",
      value: "inactive_previous",
      desc: "Service has been discontinued for this Licensee. Previously active",
      color: colors.shades.black,
      active: false,
      canSet: true,
    },
    {
      text: "Inactive - Never Active",
      value: "inactive_never",
      desc: "Licensee is no longer active, was never converted to being an active licensee.",
      color: colors.grey.darken3,
      active: false,
      canSet: true,
    },
  ],
  subscriptionPlans: [
    {
      text: "Business-in-a-Box",
      desc: "All existing feature of mytrace are available for use, includes general system updates",
      value: "standard",
    },
    {
      text: "Customized",
      desc: "Customize the instance to your business needs. This includes visual/cosmetic changes + custom functionality",
      value: "customized",
    },
  ],
  types: [
    {
      text: "Brand",
      value: "brand",
      desc: "",
    },
    {
      text: "Manufacturer",
      value: "manufacturer",
      desc: "",
    },
    {
      text: "Delivery/Distro",
      value: "distro",
      desc: "",
    },
  ],
  mytracePhases: [
    {
      text: "Admin",
      value: "admin",
      desc: "Task & Project management, Integrations with other software",
    },
    {
      text: "Sourcing",
      value: "sourcing",
      desc: "Manage brands/products, warehouses & vendor+custom orders",
    },
    {
      text: "Manufacturing",
      value: "manufacturing",
      desc: "Raw materials, Raw material orders, mixing calculator, COA management",
    },
    {
      text: "Sales",
      value: "sales",
      desc: "Account management, Sales Orders, Action Lists, Delivery routes",
    },
    {
      text: "Support",
      value: "support",
      desc: "Store Events, Trainings, Support Tickets",
    },
    {
      text: "Reporting",
      value: "reporting",
      desc: "Trace reports for all sections above & additional integrations",
    },
  ],
  productCategories: [
    {
      text: "Vapes",
      value: "vapes",
    },
    {
      text: "Concentrates",
      value: "concentrates",
    },
    {
      text: "Edibles",
      value: "edibles",
    },
    {
      text: "Flower",
      value: "flower",
    },
    {
      text: "Pre-Rolls",
      value: "preRolls",
    },
    {
      text: "Topicals",
      value: "topicals",
    },
    {
      text: "Accessories",
      value: "accessories",
    },
  ],
};

const actions = {};

const mutations = {};

const getters = {
  getAccountStatus(state) {
    return state.status;
  },
  getLicenseeTypes(state) {
    return state.types;
  },
  getProductCategories(state) {
    return state.productCategories;
  },
  getSubscriptionPlans(state) {
    return state.subscriptionPlans;
  },
  getMytracePhases(state) {
    return state.mytracePhases;
  }
};

export default {
  state,
  actions,
  mutations,
  getters,
};
