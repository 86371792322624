import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
// Fonts
import "material-design-icons-iconfont/dist/material-design-icons.css";
import { colors } from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.shades.black,
        secondary: colors.shades.white,
        bg: colors.grey.lighten2,
        bg2: colors.grey.darken3,
        ledger: colors.grey.base,
        success: colors.green.base,
        error: colors.red.base,
        update: colors.orange.base,
        review: colors.blue.base,
        contacts: colors.purple.base,
        orders: colors.orange.darken2,
        products: colors.brown.base,
        events: colors.blueGrey.base,
        money: colors.green.darken3,
        stores: colors.indigo.base,
        inProgress: colors.green.lighten2,
        highlight: colors.yellow.lighten3,
        expansionHeader: colors.blueGrey.lighten5,
        promoUnit: colors.lightBlue.darken3,
      },
      dark: {
        primary: colors.shades.black,
        secondary: colors.shades.white,
        bg: colors.grey.lighten2,
        bg2: colors.grey.darken3,
        ledger: colors.grey.base,
        success: colors.green.base,
        error: colors.red.base,
        update: colors.orange.base,
        review: colors.blue.base,
        contacts: colors.purple.base,
        orders: colors.orange.darken2,
        products: colors.brown.base,
        events: colors.blueGrey.base,
        money: colors.green.darken3,
        stores: colors.indigo.base,
        inProgress: colors.green.lighten2,
        highlight: colors.yellow.lighten3,
        expansionHeader: colors.blueGrey.lighten5,
        promoUnit: colors.lightBlue.darken3,
      },
    },
  },
  icons: {
    iconfont: "md",
    values: {
      global: "mdi-earth",
      add: "mdi-plus",
      submit: "mdi-check",
      cancel: "mdi-close-thick",
      back: "mdi-chevron-left",
      next: "mdi-chevron-right",
      update: "mdi-pencil",
      view: "mdi-eye",
      search: "mdi-magnify",
      query: "mdi-cloud-search",
      filter: "mdi-filter-multiple",
      undo: "mdi-undo",
      review: "mdi-help-circle",
      lock: "mdi-lock-open",
      orders: "mdi-clipboard-list",
      email: "mdi-email",
      phoneNumber: "mdi-phone",
      contact: "mdi-badge-account-horizontal",
      contacts: "mdi-contacts",
      money: "mdi-cash",
      products: "mdi-sitemap",
      account: "mdi-folder-account",
      ledger: "mdi-calendar-text",
      users: "mdi-account-group",
      user: "mdi-account",
      corporateEntity: "mdi-domain",
      store: "mdi-store",
      cursor: "mdi-cursor-default",
      map: "mdi-map-marker-radius",
      country: "mdi-earth",
      state: "mdi-map-marker",
      tasks: "mdi-file-tree",
      task: "mdi-calendar-check",
      events: "mdi-calendar-cursor",
      reports: "mdi-file-chart-outline",
      export: "mdi-file-export",
      demo: "mdi-projector-screen",
    },
  },
});
