<template>
  <v-container fluid class="pa-0 rounded-lg" style="height: 100% !important">
    <template v-if="rightDrawer.data.products.length">
      <v-window
        v-model="rightDrawer.data.cartWindow"
        style="width: 100% !important"
        touchless
      >
        <v-window-item :value="0">
          <v-container
            fluid
            class="pa-0 rounded-lg"
            style="height: 100% !important"
          >
            <v-row
              dense
              class="d-flex mx-1 px-2 align-center justify-space-between"
            >
              <v-col cols="1" class="text-start pa-1 flex-grow-1"
                ><span
                  class="text-caption text-lg-body-2 font-weight-bold text--disabled"
                  style="line-height: 0.75rem !important"
                  >#</span
                ></v-col
              >
              <v-col
                :cols="editMode ? 4 : 5"
                class="text-start pa-1 flex-grow-1"
              >
                <span
                  class="text-caption text-lg-body-2 font-weight-bold text--disabled"
                  style="line-height: 0.75rem !important"
                  >Item</span
                >
              </v-col>
              <v-col
                :cols="editMode ? 2 : 1"
                :class="editMode ? 'text-end' : 'text-end'"
                class="pa-1 flex-grow-1"
              >
                <span
                  class="text-caption text-lg-body-2 font-weight-bold text--disabled"
                  style="line-height: 0.75rem !important"
                  >Qty</span
                >
              </v-col>
              <v-col
                cols="2"
                :class="editMode ? 'text-end' : 'text-end'"
                class="pa-1 flex-grow-1"
              >
                <span
                  class="text-caption text-lg-body-2 font-weight-bold text--disabled"
                  style="line-height: 0.75rem !important"
                  >Price</span
                >
              </v-col>
              <v-col cols="2" class="text-end pa-1 flex-grow-1">
                <v-fade-transition>
                  <span
                    v-if="!editMode"
                    class="text-caption text-lg-body-2 font-weight-bold text--disabled"
                    style="line-height: 0.75rem !important"
                    >Total</span
                  >
                </v-fade-transition>
              </v-col>
            </v-row>
            <v-slide-y-transition group>
              <template v-for="(item, index) in products">
                <v-row
                  :key="`${item.id}_${item.promo}`"
                  class="d-flex mx-1 px-2 rounded-lg my-1 align-center justify-space-between v-row--dense"
                  :style="
                    item.promo
                      ? `background: #4a20ff24; color: #7f32a8 !important`
                      : item.sample
                      ? `background: #8adcff24; color: #0088ff !important`
                      : ''
                  "
                  group
                  :class="item.promo || item.sample ? `` : ''"
                >
                  <v-col cols="1" class="text-start pa-1 flex-grow-1"
                    ><span
                      class="text-caption text-lg-body-2 font-weight-medium"
                      style="line-height: 0.75rem !important"
                      >{{ index + 1 }}</span
                    ></v-col
                  >
                  <v-col
                    :cols="editMode ? 4 : 5"
                    class="text-start d-flex align-center justify-start pa-1 flex-grow-1"
                    style="gap: 8px"
                  >
                    <v-avatar
                      v-if="!item.imageSrc"
                      color="grey lighten-3"
                      :size="$vuetify.breakpoint.width < 500 ? 20 : 24"
                      tile
                      style="border: solid 1px #dedede !important"
                      class="rounded d-flex align-center justify-center"
                    >
                      <v-icon style="font-size: 10px !important"
                        >mdi-image</v-icon
                      >
                    </v-avatar>
                    <v-avatar
                      v-else
                      :size="$vuetify.breakpoint.width < 500 ? 20 : 24"
                      tile
                      style="border: solid 1px #dedede"
                      class="rounded white"
                    >
                      <v-img
                        contain
                        :src="item.imageSrc"
                        :alt="`${$options.filters.capitalize(
                          item.name
                        )} Product Image`"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height white ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              size="10"
                              width="2"
                              color="grey darken-2"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>
                    <div
                      class="d-flex flex-column align-start justify-center"
                      style="gap: 4px"
                    >
                      <span
                        class="text-caption text-lg-body-2 d-flex font-weight-bold"
                        style="line-height: 0.75rem !important"
                        >{{ item.name | capitalize }}</span
                      >
                      <span
                        class="text-caption text-lg-body-2 d-flex font-weight-bold"
                        style="line-height: 0.75rem !important; opacity: 0.6"
                        :style="
                          $vuetify.breakpoint.mdAndDown
                            ? 'font-size: 10px !important'
                            : 'font-size: 12px !important'
                        "
                        >{{ item.category | capitalize }}</span
                      >
                    </div>
                  </v-col>
                  <v-col
                    :cols="editMode ? 2 : 1"
                    :class="editMode ? 'text-end' : 'text-end'"
                    class="pa-1 flex-grow-1"
                  >
                    <v-menu
                      top
                      :nudge-left="100"
                      rounded="lg"
                      transition="slide-y-reverse-transition"
                      :close-on-content-click="false"
                      color="white"
                      v-model="item.menu.qty"
                      class="menuParent"
                    >
                      <template v-slot:activator="{ on, value }">
                        <div
                          class="d-flex justify-end align-center"
                          style="gap: 4px"
                          :style="editMode ? 'cursor: pointer' : ''"
                          @click="
                            item.unitWindow = 1;
                            openQTY(item);
                          "
                        >
                          <v-slide-x-transition leave-absolute hide-on-leave>
                            <v-icon
                              v-if="editMode"
                              color="grey"
                              :style="
                                $vuetify.breakpoint.width < 500
                                  ? `font-size: 10px`
                                  : `font-size: 14px`
                              "
                            >
                              mdi-pencil
                            </v-icon>
                          </v-slide-x-transition>
                          <span>
                            <span
                              class="d-flex align-center text--primary text-caption text-lg-body-2 text-no-wrap justify-end"
                            >
                              <span
                                :class="
                                  item.promo
                                    ? 'font-weight-bold'
                                    : !item.totalQTY
                                    ? 'font-weight-black red--text text--lighten-1'
                                    : 'font-weight-medium'
                                "
                                :style="
                                  item.promo
                                    ? 'color: #9421d1FF !important'
                                    : item.sample
                                    ? `color: #0088ff !important`
                                    : ''
                                "
                                v-text="
                                  $options.filters.addComma(
                                    item.qty.single +
                                      item.caseQTY * item.qty.case
                                  )
                                "
                              />
                            </span>
                          </span>
                        </div>
                      </template>
                      <v-window
                        v-model="item.unitWindow"
                        style="width: 100%"
                        class="white"
                      >
                        <v-window-item :value="1">
                          <div
                            class="d-flex flex-column white align-center pa-4 justify-center"
                            style="width: 100%; row-gap: 12px"
                          >
                            <div
                              class="d-flex flex-column text-body-1 font-weight-black align-center justify-space-between"
                            >
                              <v-sheet
                                class="d-flex flex-column align-center justify-center"
                                @click="
                                  item.unitWindow = 2;
                                  openQTY(item);
                                "
                                style="cursor: pointer"
                                ><span
                                  class="text-title text-center font-weight-medium"
                                  >Total Units</span
                                >
                                <span
                                  class="d-flex align-center text--disabled text-caption text-no-wrap justify-end justify-md-start"
                                  style="font-size: x-small !important"
                                >
                                  <span
                                    class="text-body-1 text-sm-h6 font-weight-black black--text"
                                    v-text="
                                      $options.filters.addComma(
                                        item.qty.single +
                                          item.caseQTY * item.qty.case
                                      )
                                    "
                                  />
                                </span>
                                <span
                                  class="text-caption text-md-body-2 text--disabled font-weight-medium"
                                  style="
                                    font-size: 10px !important;
                                    line-height: 0.85rem !important;
                                  "
                                  >Edit</span
                                >
                              </v-sheet>
                            </div>
                            <div style="width: 100%" class="d-flex">
                              <v-divider />
                            </div>
                            <div
                              class="d-flex flex-column justify-center align-center"
                              style="row-gap: 12px"
                            >
                              <div
                                class="d-flex justify-space-between align-center"
                                style="width: 100%; column-gap: 8px"
                              >
                                <v-btn
                                  text
                                  small
                                  :disabled="
                                    item.qty.single +
                                      item.caseQTY * item.qty.case -
                                      1 <
                                    0
                                  "
                                  color="primary"
                                  @click="decrement('single', item)"
                                >
                                  <v-icon small dark> mdi-minus </v-icon>
                                </v-btn>
                                <div
                                  class="d-flex flex-column align-center justify-space-between"
                                >
                                  <span class="font-weight-medium">{{
                                    item.qty.single
                                  }}</span>
                                  <span
                                    class="text-caption font-weight-bold text--disabled"
                                    >Single Unit{{
                                      item.qty.single === 1 ? "" : "s"
                                    }}</span
                                  >
                                </div>
                                <v-btn
                                  text
                                  small
                                  :disabled="
                                    item.qty.single +
                                      item.caseQTY * item.qty.case +
                                      1 >
                                    availableQTYForItem(item)
                                  "
                                  color="primary"
                                  @click="increment('single', item)"
                                >
                                  <v-icon small dark> mdi-plus </v-icon>
                                </v-btn>
                              </div>
                              <div
                                v-if="orderType === 'sales' && item.caseQTY"
                                class="d-flex justify-space-between align-center"
                                style="width: 100%; column-gap: 8px"
                              >
                                <v-btn
                                  text
                                  small
                                  :disabled="item.qty.case - 1 < 0"
                                  color="primary"
                                  @click="decrement('case', item)"
                                >
                                  <v-icon small dark> mdi-minus </v-icon>
                                </v-btn>
                                <div
                                  class="d-flex flex-column align-center justify-space-between"
                                >
                                  <span class="font-weight-medium">{{
                                    item.qty.case
                                  }}</span>
                                  <span
                                    class="text-caption font-weight-bold text--disabled"
                                    >Case{{
                                      item.qty.case === 1 ? "" : "s"
                                    }}</span
                                  >
                                </div>
                                <v-btn
                                  text
                                  small
                                  :disabled="
                                    item.qty.single +
                                      item.caseQTY * item.qty.case +
                                      1 * item.caseQTY >
                                    availableQTYForItem(item)
                                  "
                                  color="primary"
                                  @click="increment('case', item)"
                                >
                                  <v-icon small dark> mdi-plus </v-icon>
                                </v-btn>
                              </div>
                            </div>
                          </div>
                        </v-window-item>
                        <v-window-item :value="2">
                          <div
                            class="d-flex white align-center justify-center pa-4"
                            style="max-width: fit-content !important"
                          >
                            <div
                              class="d-flex flex-column align-center justify-center"
                              style="width: 100%; row-gap: 12px"
                            >
                              <span class="d-flex text-title font-weight-medium"
                                >Units</span
                              >
                              <div
                                class="d-flex flex-column align-center justify-center"
                                style="row-gap: 6px"
                              >
                                <v-text-field
                                  single-line
                                  outlined
                                  ref="qtyInput"
                                  placeholder="0"
                                  persistent-placeholder
                                  type="number"
                                  min="0"
                                  step="1"
                                  inputmode="decimal"
                                  v-model="item.newQTY"
                                  :color="$vuetify.theme.themes.dark.primary"
                                  dense
                                  :hide-details="
                                    item.newQTY <= availableQTYForItem(item)
                                  "
                                  hide-spin-buttons
                                  hide-details
                                  @wheel.prevent.self
                                  style="min-width: 100px"
                                  :error="
                                    item.newQTY > availableQTYForItem(item)
                                  "
                                  @keydown.esc.stop="
                                    item.unitWindow = 1;
                                    item.newQTY = item.totalQTY;
                                  "
                                  @keydown.enter="
                                    item.newQTY <= availableQTYForItem(item)
                                      ? handleProductQTYChange(
                                          item,
                                          Number(
                                            String(item.newQTY).replace('.', '')
                                          )
                                        )
                                      : ''
                                  "
                                >
                                </v-text-field>
                                <div
                                  v-if="item.newQTY > availableQTYForItem(item)"
                                  class="d-flex flex-column align-start text-caption red--text text--darken-1 justify-start"
                                  style="width: 100%"
                                >
                                  <div
                                    class="d-flex justify-start align-center"
                                  >
                                    <v-icon
                                      small
                                      :color="$vuetify.theme.themes.dark.error"
                                      >mdi-alert</v-icon
                                    >
                                    <span
                                      class="ml-1 text-no-wrap font-weight-black"
                                      >Error: Insufficient Inventory</span
                                    >
                                  </div>
                                  <span class="text-no-wrap"
                                    >Available:
                                    {{ availableQTYForItem(item) | addComma }}
                                    units</span
                                  >
                                </div>
                              </div>
                              <div
                                class="d-flex justify-space-between align-center"
                                style="width: 100%; column-gap: 8px"
                              >
                                <v-btn
                                  text
                                  small
                                  color="red"
                                  @click="item.unitWindow = 1"
                                >
                                  cancel
                                </v-btn>
                                <v-btn
                                  text
                                  small
                                  color="blue"
                                  :disabled="
                                    item.newQTY > availableQTYForItem(item) ||
                                    item.newQTY === null
                                  "
                                  @click="
                                    handleProductQTYChange(
                                      item,
                                      Number(
                                        String(item.newQTY).replace('.', '')
                                      )
                                    );
                                    item.menu.qty = false;
                                  "
                                >
                                  save
                                </v-btn>
                              </div>
                            </div>
                          </div>
                        </v-window-item>
                      </v-window>
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="2"
                    :class="editMode ? 'text-end' : 'text-end'"
                    class="pa-1 flex-grow-1"
                  >
                    <div
                      class="d-flex flex-column align-end justify-center"
                      style="min-width: 40px !important; width: 100%"
                    >
                      <div
                        class="d-flex justify-end align-center"
                        style="gap: 4px"
                      >
                        <span>
                          <span
                            class="d-flex align-center text--primary text-caption text-lg-body-2 text-no-wrap justify-end"
                          >
                            <span
                              :class="
                                item.promo
                                  ? 'font-weight-bold'
                                  : 'font-weight-medium'
                              "
                              :style="
                                item.promo
                                  ? 'color: #9421d1FF !important'
                                  : item.sample
                                  ? `color: #0088ff !important`
                                  : ''
                              "
                              v-text="$options.filters.currency(item.price)"
                            />
                          </span>
                        </span>
                      </div>
                    </div>
                  </v-col>
                  <v-col
                    cols="2"
                    :class="editMode ? 'text-end' : 'text-end'"
                    class="pa-1 flex-grow-1"
                  >
                    <v-slide-x-reverse-transition
                      leave-absolute
                      group
                      hide-on-leave
                    >
                      <template v-if="!editMode">
                        <span
                          v-if="item.totalQTY"
                          class="text-caption text--primary text-lg-body-2"
                          style="line-height: 0.75rem !important"
                          :class="
                            item.promo || item.sample
                              ? 'font-weight-bold'
                              : 'font-weight-medium'
                          "
                          :key="`${item.id}${item.promo}_productRow`"
                          :style="
                            item.promo
                              ? 'color: #9421d1FF !important'
                              : item.sample
                              ? `color: #0088ff !important`
                              : ''
                          "
                          >{{
                            ((item.qty.single + item.caseQTY * item.qty.case) *
                              item.price)
                              | currency
                          }}</span
                        >
                        <div
                          v-else
                          :key="`${item.id}${item.promo}_error`"
                          class="d-flex justify-end align-center"
                          style="gap: 6px"
                        >
                          <v-icon
                            color="red lighten-2"
                            :style="
                              $vuetify.breakpoint.width < 500
                                ? `font-size: 10px`
                                : `font-size: 14px`
                            "
                          >
                            mdi-alert
                          </v-icon>
                          <span
                            class="font-weight-black text-caption text-lg-body-2 red--text text--lighten-2 text-center"
                            >Error</span
                          >
                        </div>
                      </template>
                      <div
                        v-else
                        :key="`${item.id}${item.promo}_editActions`"
                        class="d-flex flex-no-wrap align-center justify-space-around"
                        style="gap: 4px"
                      >
                        <v-menu
                          offset-y
                          rounded="lg"
                          :nudge-bottom="8"
                          transition="scroll-y-transition"
                        >
                          <template v-slot:activator="{ on, attrs, value }">
                            <v-btn
                              v-on="on"
                              color="red lighten-2 white--text"
                              :xSmall="$vuetify.breakpoint.width < 500"
                              :small="$vuetify.breakpoint.width > 500"
                            >
                              <v-icon
                                :xSmall="$vuetify.breakpoint.width < 500"
                                :small="$vuetify.breakpoint.width > 500"
                                dark
                              >
                                mdi-trash-can
                              </v-icon>
                            </v-btn>
                          </template>
                          <v-container fluid class="white">
                            <div
                              dense
                              class="d-flex align-center flex-column"
                              style="gap: 12px"
                            >
                              <div
                                class="d-flex justify-center text--primary font-weight-medium text-body-2 text-sm-body-1"
                              >
                                Remove Item?
                              </div>
                              <div
                                class="d-flex justify-center"
                                style="gap: 12px"
                              >
                                <div class="d-flex justify-center">
                                  <v-btn small text color="blue darken-1">
                                    Cancel
                                  </v-btn>
                                </div>
                                <div class="d-flex justify-center">
                                  <v-btn
                                    small
                                    color="red lighten-2 white--text"
                                    @click="removeItem(item)"
                                  >
                                    Remove
                                  </v-btn>
                                </div>
                              </div>
                            </div>
                          </v-container>
                        </v-menu>
                      </div>
                    </v-slide-x-reverse-transition>
                  </v-col>
                  <v-col
                    cols="12"
                    v-if="!item.totalQTY"
                    class="d-flex justify-center pa-1"
                  >
                    <span
                      class="font-weight-medium text-caption text-lg-body-2 red--text text--lighten-2 text-center"
                      >Add units or remove this item to continue to
                      checkout.</span
                    >
                  </v-col>
                </v-row>
              </template>
            </v-slide-y-transition>
            <v-row
              dense
              class="d-flex mx-2 align-center justify-end mt-1 mt-sm-3 my-2"
              style="gap: 24px"
            >
              <v-col cols="12" class="text-end">
                <v-divider class="mb-3 mb-sm-6"></v-divider>
                <div
                  class="d-flex justify-space-between align-center"
                  style="min-height: 28px"
                >
                  <div
                    class="d-flex justify-space-between align-start"
                    style="width: 100% !important"
                  >
                    <v-slide-x-transition leave-absolute>
                      <div v-if="editMode" key="removeAll">
                        <v-menu
                          offset-y
                          top
                          rounded="lg"
                          :nudge-right="125"
                          transition="slide-x-transition"
                        >
                          <template v-slot:activator="{ on, attrs, value }">
                            <v-btn
                              v-on="on"
                              color="red lighten-2 white--text"
                              text
                              small
                            >
                              <div class="d-flex align-center" style="gap: 6px">
                                <v-icon dark small> mdi-trash-can </v-icon>
                                <span
                                  class="font-weight-medium text-caption text-lg-body-2 red--text text--lighten-2 text-center"
                                  >Remove All Items</span
                                >
                              </div>
                            </v-btn>
                          </template>
                          <v-container fluid class="white">
                            <div
                              dense
                              class="d-flex align-center flex-column"
                              style="gap: 12px"
                            >
                              <div
                                class="d-flex justify-center text--primary font-weight-medium text-body-2 text-sm-body-1"
                              >
                                Remove All Items?
                              </div>
                              <div
                                class="d-flex justify-center"
                                style="gap: 12px"
                              >
                                <div class="d-flex justify-center">
                                  <v-btn small text color="blue darken-1">
                                    Cancel
                                  </v-btn>
                                </div>
                                <div class="d-flex justify-center">
                                  <v-btn
                                    small
                                    color="red lighten-2 white--text"
                                    @click="clearCart"
                                  >
                                    Remove All Items
                                  </v-btn>
                                </div>
                              </div>
                            </div>
                          </v-container>
                        </v-menu>
                      </div>
                      <div
                        v-else
                        key="unitInfo"
                        class="d-flex flex-column align-start justify-start text-caption font-weight-bold text--primary"
                        style="line-height: 0.75rem !important; gap: 6px"
                      >
                        <div class="d-flex">
                          <span
                            >Units:
                            {{ totalUnits | addComma }}
                          </span>
                        </div>
                        <div class="d-flex" v-if="promoPercentage">
                          <span style="color: #7f32a8 !important"
                            >Promo Units:
                            {{
                              rightDrawer.data.products
                                .filter((item) => item.promo)
                                .reduce(
                                  (sum, item) =>
                                    sum +
                                    (item.qty.single +
                                      item.qty.case * item.caseQTY),
                                  0
                                ) | addComma
                            }}
                            ({{
                              Number(parseFloat(promoPercentage).toFixed(2))
                            }}%)
                          </span>
                        </div>
                      </div>
                    </v-slide-x-transition>
                    <div
                      class="d-flex text--primary justify-end align-end text-body-2 font-weight-bold"
                      style="line-height: 0.75rem !important; gap: 12px"
                    >
                      <span>Subtotal (COD): </span>
                      <span>
                        {{ productsTotal | currency }}
                      </span>
                    </div>
                  </div>
                </div>
                <v-fade-transition leave-aboslute hide-on-leave>
                  <div
                    v-if="!editMode"
                    class="d-flex justify-center mt-4 align-center"
                  >
                    <span
                      class="text-center text--disabled font-weight-bold text-caption"
                      style="max-width: 350px"
                    >
                      Continue to Checkout to select a payment term and view a
                      pricing comparison between all payment terms.</span
                    >
                  </div>
                </v-fade-transition>
              </v-col>
            </v-row>
          </v-container>
        </v-window-item>
        <v-window-item :value="1">
          <v-container
            fluid
            class="pa-0 rounded-lg"
            style="height: 100% !important"
          >
            <v-row>
              <v-col cols="12">
                <div class="d-flex align-center justify-space-between">
                  <span
                    class="font-weight-bold text-body-2 text-md-body-1 d-flex text--primary d-flex justify-start"
                    style="
                      cursor: pointer;
                      gap: 8px;
                      font-size: 18px !important;
                      min-height: 24px;
                    "
                    @click="activeViews.products = !activeViews.products"
                  >
                    <div class="d-flex" style="gap: 6px">
                      <span class="d-flex align-center" style="gap: 6px"
                        ><span
                          class="text-body-2 text-md-body-1 font-weight-black black--text"
                          >Products</span
                        >
                        <v-slide-x-transition leave-absolute>
                          <span
                            v-if="rightDrawer.data.products.length"
                            class="text--secondary text-body-2 text-md-body-1 font-weight-medium"
                            >({{
                              rightDrawer.data.products.length | addComma
                            }})</span
                          >
                        </v-slide-x-transition>
                      </span>
                      <span
                        :class="{
                          activeRotate: activeViews.products,
                        }"
                        style="
                          display: flex;
                          align-items: center;
                          align-self: center;
                        "
                      >
                        <v-icon size="16">mdi-chevron-down</v-icon>
                      </span>
                    </div>
                  </span>
                </div>
                <v-slide-y-transition leave-absolute group>
                  <div
                    v-if="activeViews.products"
                    class="d-flex flex-column mt-1"
                    key="productsTable"
                  >
                    <!-- <v-divider class="mb-2 mt-1"></v-divider> -->
                    <v-container fluid class="pa-0">
                      <v-row
                        dense
                        class="d-flex mx-1 px-2 align-center justify-space-between"
                      >
                        <v-col cols="1" class="text-start"
                          ><span
                            class="text-caption font-weight-bold text--secondary"
                            style="line-height: 0.75rem !important"
                            >#</span
                          ></v-col
                        >
                        <v-col cols="4" class="text-start">
                          <span
                            class="text-caption font-weight-bold text--secondary"
                            style="line-height: 0.75rem !important"
                            >Item</span
                          >
                        </v-col>
                        <v-col cols="1" class="text-end">
                          <span
                            class="text-caption font-weight-bold text--secondary"
                            style="line-height: 0.75rem !important"
                            >Qty</span
                          >
                        </v-col>
                        <v-col cols="2" class="text-end">
                          <span
                            class="text-caption font-weight-bold text--secondary"
                            style="line-height: 0.75rem !important"
                            >Price</span
                          >
                        </v-col>
                        <v-col cols="3" class="text-end">
                          <span
                            class="text-caption font-weight-bold text--secondary"
                            style="line-height: 0.75rem !important"
                            >Total</span
                          >
                        </v-col>
                      </v-row>
                      <v-row
                        dense
                        v-for="(item, index) in rightDrawer.data.products"
                        class="d-flex mx-1 px-2 rounded-lg my-1 align-center justify-space-between"
                        :style="
                          item.promo
                            ? `background: #4a20ff24; color: #7f32a8 !important`
                            : item.sample
                            ? `background: #8adcff24; color: #0088ff !important`
                            : ''
                        "
                      >
                        <v-col cols="1" class="text-start"
                          ><span
                            class="text-caption font-weight-medium"
                            style="line-height: 0.75rem !important"
                            >{{ index + 1 }}</span
                          ></v-col
                        >
                        <v-col
                          cols="4"
                          class="text-start d-flex align-center justify-start"
                          style="gap: 8px"
                        >
                          <v-avatar
                            v-if="!item.imageSrc"
                            color="grey lighten-3"
                            :size="isMobileDevice ? 20 : 22"
                            tile
                            style="border: solid 1px #dedede !important"
                            class="rounded d-flex align-center justify-center"
                          >
                            <v-icon style="font-size: 10px !important"
                              >mdi-image</v-icon
                            >
                          </v-avatar>
                          <v-avatar
                            v-else
                            :size="isMobileDevice ? 20 : 22"
                            tile
                            style="border: solid 1px #dedede"
                            class="rounded white"
                          >
                            <v-img
                              contain
                              :src="item.imageSrc"
                              :alt="`${$options.filters.capitalize(
                                item.name
                              )} Product Image`"
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height white ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    size="10"
                                    width="2"
                                    color="grey darken-2"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-avatar>
                          <span
                            class="text-caption d-flex font-weight-medium"
                            style="line-height: 0.75rem !important"
                            >{{ item.name | capitalize }}</span
                          >
                        </v-col>
                        <v-col cols="1" class="text-end">
                          <span
                            class="text-caption font-weight-medium"
                            style="line-height: 0.75rem !important"
                            >{{
                              (item.qty.single + item.qty.case * item.caseQTY)
                                | addComma
                            }}</span
                          >
                        </v-col>
                        <v-col cols="2" class="text-end">
                          <span
                            class="text-caption font-weight-medium"
                            style="line-height: 0.75rem !important"
                            >{{ item.price | currency }}</span
                          >
                        </v-col>
                        <v-col cols="3" class="text-end">
                          <span
                            class="text-caption font-weight-medium"
                            style="line-height: 0.75rem !important"
                            >{{
                              (item.price *
                                (item.qty.single +
                                  item.qty.case * item.caseQTY))
                                | currency
                            }}</span
                          >
                        </v-col>
                      </v-row>
                      <v-row
                        dense
                        class="d-flex mx-2 align-center justify-end mt-1 my-2"
                      >
                        <v-col cols="12" class="text-end">
                          <v-divider class="mb-3"></v-divider>
                          <div class="d-flex justify-space-between align-start">
                            <div
                              class="d-flex flex-column align-start justify-center text-caption font-weight-bold text--primary"
                              style="line-height: 0.75rem !important; gap: 6px"
                            >
                              <div class="d-flex">
                                <span
                                  >Units:
                                  {{ totalUnits | addComma }}
                                </span>
                              </div>
                              <div class="d-flex" v-if="promoPercentage">
                                <span style="color: #7f32a8 !important"
                                  >Promo Units:
                                  {{
                                    rightDrawer.data.products
                                      .filter((item) => item.promo)
                                      .reduce(
                                        (sum, item) =>
                                          sum +
                                          (item.qty.single +
                                            item.qty.case * item.caseQTY),
                                        0
                                      ) | addComma
                                  }}
                                  ({{
                                    Number(
                                      parseFloat(promoPercentage).toFixed(2)
                                    )
                                  }}%)
                                </span>
                              </div>
                            </div>
                            <div
                              class="d-flex text--primary justify-end align-end text-body-2 font-weight-bold"
                              style="line-height: 0.75rem !important; gap: 6px"
                            >
                              <span>Subtotal: </span>
                              <span>
                                {{ productsTotal | currency }}
                              </span>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-divider class="mb-0" />
                  </div>
                </v-slide-y-transition>
              </v-col>
              <v-col cols="12">
                <div class="d-flex" style="gap: 6px">
                  <span class="d-flex align-center" style="gap: 6px"
                    ><span
                      class="text-body-2 text-md-body-1 font-weight-black black--text"
                      >Order Metadata</span
                    >
                  </span>
                </div>
              </v-col>
              <v-col cols="12" class="d-flex">
                <div class="px-2" style="width: 100% !important">
                  <v-sheet class="elevation-2 pa-4 rounded">
                    <div class="d-flex justify-space-between fill-width">
                      <div
                        class="d-flex align-center justify-start"
                        style="gap: 12px"
                      >
                        <v-avatar
                          tile
                          class="rounded-lg"
                          color="grey lighten-3"
                        >
                          <v-icon>mdi-account</v-icon>
                        </v-avatar>
                        <span
                          class="d-flex justify-space-between flex-shrink-1"
                        >
                          <div
                            class="align-start justify-start align-self-start flex-column d-flex text-body-2"
                            style="gap: 4px"
                          >
                            <span
                              class="font-weight-bold text-body-2 text-capitalize"
                            >
                              {{ `${getUser.name.first} ${getUser.name.last}` }}
                            </span>
                            <span
                              v-if="getUser.title"
                              class="text-capitalize text-caption text--secondary font-weight-bold"
                              v-text="getUser.title"
                              style="line-height: 0.5rem !important"
                            />
                          </div>
                        </span>
                      </div>
                      <span
                        class="text-capitalize d-flex align-center text-caption font-weight-bold"
                      >
                        <span
                          class="font-weight-bold text-center text-caption text-no-wrap rounded-pill"
                          :style="`background-color: #2196f333 !important; color: #2196f3 !important`"
                          style="padding: 3px 10px; min-width: 47px"
                        >
                          You
                        </span>
                      </span>
                    </div>
                    <div class="d-flex flex-column mt-1">
                      <v-divider class="mb-2 mt-2"></v-divider>
                      <div class="d-flex justify-space-between pl-2 pr-1">
                        <div
                          class="d-flex flex-column align-items-stretch justify-space-around"
                        >
                          <div class="d-flex align-center justify-start">
                            <v-icon small left>mdi-email</v-icon>
                            <span
                              class="text-caption font-weight-medium text--secondary"
                              :class="
                                !getUser.email?.length
                                  ? 'text--disabled font-weight-normal'
                                  : ''
                              "
                              >{{
                                getUser.email?.length ? getUser.email : "-"
                              }}</span
                            >
                          </div>
                          <div class="d-flex align-center justify-start">
                            <v-icon small left>mdi-phone</v-icon>
                            <span
                              class="text-caption text--secondary"
                              :class="
                                !getUser.phoneNumber?.length
                                  ? 'text--disabled font-weight-normal'
                                  : 'font-weight-medium'
                              "
                              >{{
                                getUser.phoneNumber?.length
                                  ? $options.filters.phoneNumber(
                                      getUser.phoneNumber
                                    )
                                  : "No Phone Number"
                              }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-sheet>
                </div>
              </v-col>
              <v-col cols="6">
                <v-textarea
                  label="Delivery Preferences"
                  outlined
                  rows="3"
                  background-color="grey lighten-4"
                  class="pl-2"
                  :color="$vuetify.theme.themes.dark.primary"
                  hide-details
                  v-model="deliveryPrefs"
                >
                </v-textarea>
              </v-col>
              <v-col cols="6">
                <v-textarea
                  label="Special Requests"
                  :color="$vuetify.theme.themes.dark.primary"
                  outlined
                  rows="3"
                  class="pr-2"
                  hide-details="auto"
                  v-model="notes"
                >
                </v-textarea>
              </v-col>
              <v-col cols="12">
                <div class="d-flex" style="gap: 6px">
                  <span class="d-flex align-center" style="gap: 6px"
                    ><span
                      class="text-body-2 text-md-body-1 font-weight-black black--text"
                      >Select a Payment Term</span
                    >
                  </span>
                </div>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-item-group
                  v-model="selectedPaymentTerm"
                  @change="updatePricing"
                  mandatory
                >
                  <v-container>
                    <v-row dense>
                      <v-col
                        v-for="term in netTotalsForProducts"
                        :key="term.value"
                        cols="3"
                        sm="auto"
                        class="flex-grow-1 flex-shrink-0"
                      >
                        <v-item v-slot="{ active, toggle }">
                          <v-sheet
                            class="d-flex flex-grow-1 pa-3 flex-column align-center justify-space-around rounded-lg softShadow"
                            height="125"
                            style="cursor: pointer; max-width: 100%"
                            :style="isMobileDevice ? '' : 'min-width: 140px'"
                            @click="toggle"
                          >
                            <div class="d-flex flex-column align-center">
                              <span
                                class="font-weight-black text-body-2 text-sm-h6"
                                >{{ term.text }}</span
                              >
                              <span
                                class="font-weight-bold text--secondary text-caption text-sm-body-2"
                                >{{ term.amount | currency }}</span
                              >
                            </div>
                            <v-fade-transition leave-absolute hide-on-leave>
                              <v-icon
                                v-if="active"
                                color="blue darken-1"
                                :size="isMobileDevice ? 24 : 30"
                                key="check-circle-outline"
                                v-text="'mdi-check-circle-outline'"
                              ></v-icon>
                              <v-icon
                                v-else
                                color="grey lighten-1"
                                :size="isMobileDevice ? 24 : 30"
                                key="circle-outline"
                                v-text="'mdi-circle-outline'"
                              ></v-icon>
                            </v-fade-transition>
                          </v-sheet>
                        </v-item>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-item-group>
              </v-col>
              <v-col cols="12">
                <div class="d-flex justify-end" style="gap: 6px">
                  <span class="d-flex align-center justify-end" style="gap: 6px"
                    ><span class="text-body-1 font-weight-black black--text"
                      >Total: </span
                    ><span class="text-body-1 font-weight-black black--text">{{
                      orderTotal | currency
                    }}</span>
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-window-item>
      </v-window>
    </template>
    <template v-else>
      <div class="d-flex justify-center align-center flex-column">
        <div class="d-flex justify-start mt-2 text--disabled">
          <v-icon large>mdi-cart-remove</v-icon>
        </div>
        <span
          class="mt-1 mb-3 text-body-1 text-md-h6 font-weight-normal text--secondary"
        >
          No Products Added
        </span>
        <span
          class="d-block text-caption font-weight-medium text-md-body-2 text--disabled"
        >
          Add a product to your cart to checkout!</span
        >
      </div>
    </template>
    <v-dialog
      v-model="contactDialog"
      content-class="rounded-lg"
      scrollable
      max-width="600px"
    >
      <v-card style="background-color: #f9fafc">
        <v-card-title>
          <div style="gap: 12px" class="d-flex justify-start align-center">
            <v-icon>mdi-account-box-plus-outline</v-icon>
            <span class="text-h6 font-weight-black">New Contact</span>
          </div>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="First Name"
                  persistent-placeholder
                  placeholder="John"
                  hide-details
                  v-model="newContact.name.first"
                  :color="$vuetify.theme.themes.dark.primary"
                  :background-color="
                    newContact.name.first?.length ? '' : 'yellow lighten-3'
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Last Name"
                  persistent-placeholder
                  placeholder="Doe"
                  hide-details
                  v-model="newContact.name.last"
                  :color="$vuetify.theme.themes.dark.primary"
                  :background-color="
                    newContact.name.last?.length ? '' : 'yellow lighten-3'
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  label="Title"
                  placeholder="Select"
                  persistent-placeholder
                  :color="$vuetify.theme.themes.dark.primary"
                  :item-color="$vuetify.theme.themes.dark.primary"
                  :background-color="newContact.title ? '' : 'yellow lighten-3'"
                  :items="getContactTitles.filter((item) => item.value)"
                  hide-details
                  clearable
                  return-object
                  v-model="newContact.title"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Phone #"
                  placeholder="(123) 456-7890"
                  persistent-placeholder
                  hide-details
                  type="tel"
                  v-model="newContact.phoneNumber"
                  :color="$vuetify.theme.themes.dark.primary"
                  :background-color="
                    newContactHasEmailOrPhone ? '' : 'yellow lighten-3'
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Email"
                  type="email"
                  placeholder="john.doe@gmail.com"
                  persistent-placeholder
                  hide-details
                  v-model="newContact.email"
                  :color="$vuetify.theme.themes.dark.primary"
                  :background-color="
                    newContactHasEmailOrPhone ? '' : 'yellow lighten-3'
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <PlacesSearch
                  label="Address"
                  :required="true"
                  :placeholderText="`Input Address`"
                  :types="`address`"
                  :address="newContact.locationInput"
                  @place-search="placeSearch"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="Notes"
                  placeholder="Enter notes..."
                  persistent-placeholder
                  outlined
                  rows="3"
                  :color="$vuetify.theme.themes.dark.primary"
                  background-color="grey lighten-3"
                  hide-details
                  v-model="newContact.notes"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="px-4 pb-4">
          <v-btn
            color="red lighten-1"
            text
            :small="$vuetify.breakpoint.smAndDown"
            :disabled="loading.submit"
            @click="handleContactSubmit()"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            :small="$vuetify.breakpoint.smAndDown"
            @click="handleContactSubmit(true)"
            :disabled="!validContact"
            :loading="loading.submit"
          >
            Create Contact
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="errorDialog"
      content-class="rounded-lg"
      persistent
      scrollable
      max-width="600px"
    >
      <v-card style="background-color: #f9fafc">
        <v-card-title>
          <div style="gap: 12px" class="d-flex justify-start align-center">
            <v-icon color="red">mdi-alert</v-icon>
            <span class="text-h6 font-weight-black">Error</span>
          </div>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-container>
            <v-row>
              <v-col cols="12">
                <div
                  style="gap: 12px"
                  class="d-flex flex-column align-center justify-center text-center"
                >
                  <span
                    class="text-body-2 text-md-body-1 text--primary font-weight-black"
                    >There was an error submitting your order.</span
                  >
                  <span class="text-caption text-md-body-2 font-weight-medium"
                    >Please consider saving your order as a Draft Order and
                    trying to submit again in 15 minutes.
                  </span>
                  <span
                    class="text-caption text-md-body-2 font-weight-black text--secondary"
                    >If this error persists, please contact a
                    {{ getAppName }} administrator.</span
                  >
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="px-4 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            :small="$vuetify.breakpoint.smAndDown"
            @click="errorDialog = false"
          >
            Dismiss
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// Libraries
import { mapActions, mapGetters } from "vuex";
import {
  // Firestore
  firestore,
  collection,
  onSnapshot,
  getDoc,
  getDocs,
  doc,
  updateDoc,
  serverTimestamp,
  query,
  where,
  or,
  writeBatch,
  increment,
  limit,
  deleteDoc,
  // Functions
  functions,
  httpsCallable,

  // Storage
  storage,
  ref,
  getDownloadURL,
  getMetadata,
  getBytes,
  uploadBytes,
} from "@/firebase/init";
import moment from "moment";
// import jsPDF from "jspdf";
// import autoTable from "jspdf-autotable";
import { Buffer } from "buffer";
import gmapsInit from "@/utils/gmaps";
// Components

import TextWithIconOverlay from "@/components/ui/orders/TextWithIconOverlay.vue";
import PlacesSearch from "@/components/api/google-maps/places/Places_Search";

export default {
  name: "OrderCart",
  data: () => ({
    // editMode: false,
    loading: {
      submit: false,
      draft: false,
      invoice: false,
    },
    errorDialog: false,
    contactDialog: false,
    selectedPaymentTerm: 0,
    deliveryPrefs: "",
    notes: "",
    internalNotes: "",
    shippingFee: 0.0,
    contact: null,
    approvalConfirmation: null,
    activeViews: {
      products: false,
      approvalConfirmation: false,
    },
    newContact: {
      name: {
        first: "",
        last: "",
      },
      phoneNumber: "",
      email: "",
      title: null,
      notes: "",
      address: null,
      locationInput: "",
      location: null,
    },
    google: null,
    geocoder: null,
  }),
  props: {
    products: {
      type: Array,
      required: true,
    },
    accountDetails: {
      type: Object,
      required: true,
    },
    orderType: {
      type: String,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  components: {
    TextWithIconOverlay,
    PlacesSearch,
  },
  async created() {
    this.setup();
  },
  async mounted() {
    // await this.setupGoogleAPI();
  },
  methods: {
    ...mapActions(["_rightDrawer"]),
    /* API */
    // GET
    // POST
    async saveDraft() {
      this.loading.draft = true;

      setTimeout(() => {
        this.loading.draft = false;
      }, 3000);

      return;

      const batch = writeBatch(firestore);

      const draftSalesOrderDoc = doc(
        collection(firestore, "salesOrders-draft")
      );
      let users = [this.getUser.uid];
      const selectedAccount =
        this.getRightDrawer.data.accountDetails.brandStats;
      const brand = this.getRightDrawer.data.accountDetails.brandStats.brand;

      if (
        selectedAccount.staff.manager.id &&
        selectedAccount.staff.manager.id.length > 0
      ) {
        users.push(selectedAccount.staff.manager.id);
      }
      if (
        selectedAccount.staff.executive.id &&
        selectedAccount.staff.executive.id.length > 0
      ) {
        users.push(selectedAccount.staff.executive.id);
      }

      const selectedUnits = this.getRightDrawer.data.products;

      let addedUnits = [...selectedUnits];
      users = [...new Set(users)]; //  Removes duplicates

      const lineItems = addedUnits
        .map((lineItem) => {
          return {
            account: {
              id: this.getRightDrawer.data.accountDetails.account.id,
              name: this.getRightDrawer.data.accountDetails.account.name,
            },
            brand: {
              id: brand.id,
              name: brand.name,
            },
            state:
              this.getRightDrawer.data.accountDetails.account.location.shipping
                .state,
            amount: lineItem.price,
            notes: lineItem.notes ?? "",
            flags: {
              case: lineItem.soldByCase || lineItem.caseQTY,
              fulfilled: false,
              delivered: false,
              onSale: lineItem.price < lineItem.originalPrice,
            },
            externalIDs: {
              leaflink: lineItem.externalIDs.leaflink,
            },
            name: lineItem.name,
            price: {
              normal: lineItem.originalPrice,
              sale: lineItem.price,
            },
            productID: lineItem.id,
            categoryID: lineItem.categoryID,
            categoryName: lineItem.category,
            qty: lineItem.qty.case * lineItem.caseQTY + lineItem.qty.single,
            unitsPerCase: lineItem.caseQTY || 0,
            cases: lineItem.qty.case || 0,
            singles: lineItem.qty.single || 0,
            relatedID: draftSalesOrderDoc.id,
            sample: lineItem.sample,
            promo: lineItem.promo || false,
            sku: lineItem.sku.toLowerCase(),
            tax: 0,
          };
        })
        .sort((a, b) => (a.name > b.name ? 1 : -1));

      // Order
      let externalIDs = {};

      let contact = this.getRightDrawer.data.contacts.find(
        (contact) => contact.value === this.contact
      );

      batch.set(
        draftSalesOrderDoc,
        {
          created: serverTimestamp(),
          updated: serverTimestamp(),
          createdBy: {
            name: this.getUser.name,
            uid: this.getUser.uid,
            email: this.getUser.email,
          },
          lastUpdated: {
            name: this.getUser.name,
            uid: this.getUser.uid,
          },
          flags: {
            external: false,
            paid: false,
            repGenerated: true,
            sample: this.orderType === "sample" ? true : false,
          },
          orderNumber: {
            external: "",
            internal: "",
          },
          externalIDs,
          brand: {
            id: brand.id,
            name: brand.name,
          },
          lineItems,
          account: {
            id: this.getRightDrawer.data.accountDetails.account.id,
            name: this.getRightDrawer.data.accountDetails.account.name,
            phone: this.getRightDrawer.data.accountDetails.account.phoneNumber,
            license:
              this.getRightDrawer.data.accountDetails.account.license.number,
            email: this.getRightDrawer.data.accountDetails.account.email,
            deliveryPreferences: this.deliveryPrefs,
          },
          location: {
            billing: {
              city: this.getRightDrawer.data.accountDetails.account.location
                .billing.city.name,
              state:
                this.getRightDrawer.data.accountDetails.account.location.billing
                  .state,
              street:
                this.getRightDrawer.data.accountDetails.account.location.billing
                  .street,
              zipCode:
                this.getRightDrawer.data.accountDetails.account.location.billing
                  .zipCode,
            },
            shipping: {
              city: this.getRightDrawer.data.accountDetails.account.location
                .shipping.city.name,
              state:
                this.getRightDrawer.data.accountDetails.account.location
                  .shipping.state,
              street:
                this.getRightDrawer.data.accountDetails.account.location
                  .shipping.street,
              zipCode:
                this.getRightDrawer.data.accountDetails.account.location
                  .shipping.zipCode,
            },
          },
          balance: {
            due: this.orderTotal,
            excise: 0,
            shipping: this.shippingFee,
            total: this.orderTotal,
          },
          paymentTerm: this.getRightDrawer.data.paymentTerm.value,
          shipDate: null,
          units: this.totalUnits,
          notes: this.notes,
          internalNotes: this.internalNotes,
          status: "draftOrder",
          buyerInformation: contact
            ? {
                name: contact.fullName,
                email: contact.email,
                id: contact.id,
              }
            : null,
          users,
        },
        { merge: true }
      );

      batch.set(
        doc(collection(firestore, "ledger")),
        {
          created: serverTimestamp(),
          relatedID: draftSalesOrderDoc.id,
          relatedTo: "salesOrders",
          action: `<span class="text-capitalize">${this.getUser.name.first} ${this.getUser.name.last}</span> created Draft Order for <span class="text-capitalize">${this.getRightDrawer.data.accountDetails.account.name}</span>`,
          user: {
            name: this.getUser.name,
            uid: this.getUser.uid,
          },
        },
        { merge: true }
      );

      await batch.commit();
      this.loading.draft = false;
      // Show feedback after draft save success
      this.rightDrawer.active = false;
    },
    async submit() {
      this.loading.submit = true;

      setTimeout(() => {
        this.loading.submit = false;
      }, 3000);

      return;

      let batch = writeBatch(firestore);

      let externalOrder = null,
        users = [this.getUser.uid];

      let payableToResponse = await getDocs(
        query(
          collection(firestore, "payableTo"),
          where(
            "location.state.stateID",
            "==",
            this.rightDrawer.data.accountDetails.account.location.shipping.state
              .stateID
          )
        )
      );
      const salesOrderDoc = doc(collection(firestore, "salesOrders"));

      const stateDoc = doc(
        firestore,
        "states",
        this.rightDrawer.data.accountDetails.account.location.shipping.state
          .stateID
      );
      const stateResponse = await getDoc(stateDoc);

      let fulfillmentLocation = stateResponse.data().fulfillmentLocation;

      const locationRef = doc(
        firestore,
        "locationManufacturing",
        `${fulfillmentLocation}_${this.rightDrawer.data.accountDetails.brandStats.brand.id}`
      );

      let payableTo = null;
      if (!payableToResponse.empty) {
        payableTo = {
          id: payableToResponse.docs[0].id,
          ...payableToResponse.docs[0].data(),
        };
      }

      let abort = false;
      let lineItems = [];
      try {
        const selectedItems = this.rightDrawer.data.products;
        lineItems = selectedItems
          .map((lineItem) => {
            return {
              account: {
                id: this.rightDrawer.data.accountDetails.account.id,
                name: this.rightDrawer.data.accountDetails.account.name,
              },
              brand: {
                id: this.rightDrawer.data.accountDetails.brandStats.brand.id,
                name: this.rightDrawer.data.accountDetails.brandStats.brand
                  .name,
              },
              state:
                this.rightDrawer.data.accountDetails.account.location.shipping
                  .state,
              amount: lineItem.price,
              notes: lineItem.notes,
              flags: {
                case: lineItem.soldByCase || lineItem.caseQTY > 0,
                fulfilled: false,
                delivered: false,
                onSale: lineItem.price < lineItem.originalPrice,
              },
              externalIDs: {
                leaflink: lineItem.externalIDs.leaflink,
              },
              name: lineItem.name,
              price: {
                normal: lineItem.originalPrice,
                sale: lineItem.price,
              },
              productID: lineItem.id,
              categoryID: lineItem.categoryID,
              categoryName: lineItem.category,
              qty: lineItem.qty.case * lineItem.caseQTY + lineItem.qty.single,
              unitsPerCase: lineItem.caseQTY || 0,
              cases: lineItem.qty.case || 0,
              singles: lineItem.qty.single || 0,
              relatedID: salesOrderDoc.id,
              sample: lineItem.sample,
              promo: lineItem.promo || false,
              sku: lineItem.sku.toLowerCase(),
              tax: 0,
            };
          })
          .sort((a, b) => (a.name > b.name ? 1 : -1));

        let contact = this.getRightDrawer.data.contacts.find(
          (contact) => contact.value === this.contact
        );

        let externalIDs = {};

        batch.set(
          salesOrderDoc,
          {
            created: serverTimestamp(),
            updated: serverTimestamp(),
            createdBy: {
              name: this.getUser.name,
              uid: this.getUser.uid,
              email: this.getUser.email,
            },
            lastUpdated: {
              name: this.getUser.name,
              uid: this.getUser.uid,
            },
            flags: {
              external:
                payableTo?.flags.leaflink &&
                this.rightDrawer.data.accountDetails.account.externalIDs
                  .leaflink
                  ? true
                  : false,
              paid: false,
              repGenerated: true,
              sample: this.orderType === "sample" ? true : false,
            },
            orderNumber: {
              external:
                payableTo?.flags.leaflink &&
                this.rightDrawer.data.accountDetails.account.externalIDs
                  .leaflink &&
                externalOrder
                  ? externalOrder.short_id
                  : "",
              internal:
                this.rightDrawer.data.accountDetails.brandStats.metrics.orders
                  .total + 1,
            },
            externalIDs,
            brand: {
              id: this.rightDrawer.data.accountDetails.brandStats.brand.id,
              name: this.rightDrawer.data.accountDetails.brandStats.brand.name,
            },
            lineItems,
            account: {
              id: this.rightDrawer.data.accountDetails.account.id,
              name: this.rightDrawer.data.accountDetails.account.name,
              phone: this.rightDrawer.data.accountDetails.account.phoneNumber,
              license:
                this.rightDrawer.data.accountDetails.account.license.number,
              email: this.rightDrawer.data.accountDetails.account.email,
              staff: {
                manager: {
                  email:
                    this.rightDrawer.data.accountDetails.brandStats.staff
                      .manager.email,
                  id:
                    this.rightDrawer.data.accountDetails.brandStats.staff
                      .manager.id?.length > 0
                      ? this.rightDrawer.data.accountDetails.brandStats.staff
                          .manager.id
                      : null,
                  name: this.rightDrawer.data.accountDetails.brandStats.staff
                    .manager.name,
                },
                executive: {
                  email:
                    this.rightDrawer.data.accountDetails.brandStats.staff
                      .executive.email,
                  id:
                    this.rightDrawer.data.accountDetails.brandStats.staff
                      .executive.id?.length > 0
                      ? this.rightDrawer.data.accountDetails.brandStats.staff
                          .executive.id
                      : null,
                  name: this.rightDrawer.data.accountDetails.brandStats.staff
                    .executive.name,
                },
              },
              deliveryPreferences: this.deliveryPrefs,
            },
            location: {
              billing: {
                city: this.rightDrawer.data.accountDetails.account.location
                  .billing.city.name,
                state:
                  this.rightDrawer.data.accountDetails.account.location.billing
                    .state,
                street:
                  this.rightDrawer.data.accountDetails.account.location.billing
                    .street,
                zipCode:
                  this.rightDrawer.data.accountDetails.account.location.billing
                    .zipCode,
              },
              shipping: {
                city: this.rightDrawer.data.accountDetails.account.location
                  .shipping.city.name,
                state:
                  this.rightDrawer.data.accountDetails.account.location.shipping
                    .state,
                street:
                  this.rightDrawer.data.accountDetails.account.location.shipping
                    .street,
                zipCode:
                  this.rightDrawer.data.accountDetails.account.location.shipping
                    .zipCode,
              },
            },
            balance: {
              due: this.orderTotal,
              excise: 0,
              shipping: this.shippingFee,
              total: this.orderTotal,
            },
            paymentTerm: this.rightDrawer.data.paymentTerm.value,
            shipDate: null,
            units: this.totalUnits,
            notes: this.notes,
            internalNotes: this.internalNotes,
            status: this.orderNeedsApproval ? "draft" : "submitted",
            buyerInformation: {
              name: contact.fullName,
              email: contact.email,
            },
            users,
            records: {
              awaitingApproval: this.orderNeedsApproval
                ? moment().startOf("day").add(12, "hours").toDate()
                : null,
              submitted: !this.orderNeedsApproval
                ? moment().startOf("day").add(12, "hours").toDate()
                : null,
              accepted: null,
              deliveryConfirmation: null,
              fulfilled: null,
              shipped: null,
              completed: null,
              cancelled: null,
            },
          },
          { merge: true }
        );

        await batch.commit();
      } catch (error) {
        console.log(error);
        abort = true;
      } finally {
        // Check to make sure the order was successfully submitted
        //    - if the order was successfully submitted, add metrics to brandStats and push user to order view
        //    - if the order was not successfully submitted, delete the order on MyTrace and/or Leaflink.
        let orderResponse = await getDoc(salesOrderDoc);
        batch = writeBatch(firestore);
        if (orderResponse.exists()) {
          // Order successfully submitted
          batch.set(
            doc(
              firestore,
              "brandStats",
              `${this.rightDrawer.data.accountDetails.account.id}_${this.rightDrawer.data.accountDetails.brandStats.brand.id}`
            ),
            {
              metrics: {
                last: {
                  order: moment().startOf("day").toDate(),
                },
                orders: {
                  total: increment(1),
                },
              },
            },
            { merge: true }
          );

          // Update status to Samples Sent
          if (
            this.orderType === "sample" &&
            ["lead_cold", "lead_hot"].includes(
              this.rightDrawer.data.accountDetails.brandStats.status.value
            )
          ) {
            const oldValue = this.getAccountStatus.find(
                (option) =>
                  option.value ===
                  this.rightDrawer.data.accountDetails.brandStats.status.value
              ),
              newValue = this.getAccountStatus.find(
                (option) => option.value === "samples_sent"
              );

            batch.set(
              doc(
                firestore,
                "brandStats",
                `${this.rightDrawer.data.accountDetails.account.id}_${this.rightDrawer.data.accountDetails.brandStats.brand.id}`
              ),
              {
                status: "samples_sent",
              },
              { merge: true }
            );
            batch.set(
              doc(collection(firestore, "ledger")),
              {
                created: serverTimestamp(),
                relatedID: `${this.rightDrawer.data.accountDetails.account.id}_${this.rightDrawer.data.accountDetails.brandStats.brand.id}`,
                relatedTo: "accounts",
                action: `<span class="text-capitalize">${
                  this.getUser.name.first
                } ${
                  this.getUser.name.last
                }</span> <span class="text-uppercase font-weight-bold" style="color: ${
                  this.$vuetify.theme.themes.dark.update
                };">updated</span> <span class="text-capitalize">${
                  this.rightDrawer.data.accountDetails.brandStats.brand.name
                }</span> with Order #${
                  this.rightDrawer.data.accountDetails.brandStats.metrics.orders
                    .total + 1
                } | Status - <span class="text-uppercase">old</span>: <span class="font-weight-bold" style="color: ${
                  oldValue.color
                };">${
                  oldValue.text
                }</span> => <span class="text-uppercase">new</span>: <span class="font-weight-bold" style="color: ${
                  newValue.color
                };">${newValue.text}</span>`,
                user: {
                  name: this.getUser.name,
                  uid: this.getUser.uid,
                },
              },
              { merge: true }
            );
          }
          let approvalReasons = [];
          let approvalNotes = "";
          if (this.orderNeedsApproval) {
            if (this.accountStatusNeedsApproval) {
              approvalReasons.push("Account Status");
            }
            if (this.productsOnSale) {
              approvalReasons.push(`Products on Sale`);
            }
            if (this.accountHasBalance) {
              approvalReasons.push("Account Balance is greater than zero");
            }
            approvalNotes = ` | Reason${
              approvalReasons.length === 1 ? "" : "s"
            } for approval requirement: ${approvalReasons.join(", ")}`;

            const userDoc = doc(firestore, "users", this.getUser.uid);
            const userResponse = await getDoc(userDoc);

            if (userResponse.data().reportsTo?.length > 0) {
              //  Send notification to currentUser.reportsTo

              const userDoc = doc(
                firestore,
                "users",
                userResponse.data().reportsTo
              );
              const response = await getDoc(userDoc);

              let reportsTo = null;

              let notificationNotes = `Order Approval Required`;

              if (response.exists()) {
                reportsTo = {
                  name: response.data().name,
                  id: userResponse.data().reportsTo,
                };
                batch.set(
                  doc(collection(firestore, "notifications")),
                  {
                    created: serverTimestamp(),
                    updated: serverTimestamp(),
                    assignedTo: {
                      name: reportsTo.name,
                      uid: reportsTo.id,
                    },
                    createdBy: {
                      name: this.getUser.name,
                      uid: this.getUser.uid,
                    },
                    lastUpdated: {
                      name: this.getUser.name,
                      uid: this.getUser.uid,
                    },
                    notes: notificationNotes,
                    relatedTo: {
                      id: salesOrderDoc.id,
                      resource: "salesOrder",
                      name: `${this.$options.filters.capitalize(
                        this.rightDrawer.data.accountDetails.account.name
                      )} #${
                        this.rightDrawer.data.accountDetails.brandStats.metrics
                          .orders.total + 1
                      }`,
                      type: "sales order",
                    },
                    viewed: false,
                  },
                  { merge: true }
                );
              }
            }
          }

          batch.set(
            doc(collection(firestore, "ledger")),
            {
              created: serverTimestamp(),
              relatedID: salesOrderDoc.id,
              relatedTo: "salesOrders",
              action: `<span class="text-capitalize">${
                this.getUser.name.first
              } ${
                this.getUser.name.last
              }</span> created Sales Order for <span class="text-capitalize">${
                this.rightDrawer.data.accountDetails.account.name
              }</span> | <span class="text-uppercase">status</span>: <span class="text-capitalize font-weight-bold">${
                this.orderNeedsApproval ? "awaiting approval" : "submitted"
              }</span>${approvalNotes}`,
              user: {
                name: this.getUser.name,
                uid: this.getUser.uid,
              },
            },
            { merge: true }
          );
          await batch.commit();
        }
      }

      batch = writeBatch(firestore);
      if (!abort) {
        // Create LeafLink Order

        try {
          if (
            payableTo?.flags.leaflink &&
            this.rightDrawer.data.accountDetails.account.externalIDs.leaflink
          ) {
            const createLeafLinkOrder = httpsCallable(functions, "externalAPI");
            const { data } = await createLeafLinkOrder({
              method: "POST",
              headers: {
                Authorization: `App ${payableTo?.integrations.leaflink.api}`,
              },
              url: `${process.env.VUE_APP_LEAFLINK}api/v2/orders-received/`,
              data: {
                brand: payableTo?.integrations.leaflink.brandID,
                seller: payableTo?.integrations.leaflink.seller,
                customer: {
                  id: Number(
                    this.rightDrawer.data.accountDetails.account.externalIDs
                      .leaflink
                  ),
                },
                notes: this.notes,
                internal_notes: this.internalNotes,
                delivery_preferences: this.deliveryPrefs,
                payment_term: this.rightDrawer.data.paymentTerm.text,
                status: "Submitted",
                shipping_charge: {
                  amount: this.shippingFee,
                  currency: "USD",
                },
                line_items: lineItems.map((item) => {
                  return {
                    product: item.externalIDs.leaflink,
                    quantity: item.qty,
                    ordered_unit_price: {
                      amount: item.amount,
                      currency: "USD",
                    },
                    sale_price: {
                      amount: item.amount,
                      currency: "USD",
                    },
                    notes: item.notes,
                    is_sample: item.sample,
                  };
                }),
              },
            });
            externalOrder = data;

            const { data: data2 } = await createLeafLinkOrder({
              method: "GET",
              headers: {
                Authorization: `App ${payableTo?.integrations.leaflink.api}`,
              },
              url: `${process.env.VUE_APP_LEAFLINK}api/v2/orders-received/${data.number}/?include_children=line_items`,
            });

            // Order
            let externalIDs = {};
            if (
              payableTo?.flags.leaflink &&
              this.rightDrawer.data.accountDetails.account.externalIDs.leaflink
            ) {
              externalIDs = {
                leaflink: externalOrder.number,
              };
            }

            batch.set(
              salesOrderDoc,
              {
                externalIDs,
                orderNumber: {
                  external: externalOrder.short_id ?? "",
                },
              },
              { merge: true }
            );

            lineItems.forEach((lineItem) => {
              let leaflinkLineItem = data2.line_items.find((item) => {
                return (
                  item.bulk_units === lineItem.qty &&
                  item.product === lineItem.externalIDs.leaflink &&
                  item.ordered_unit_price.amount === lineItem.amount
                );
              });

              lineItem.externalIDs = {
                ...lineItem.externalIDs,
                leaflinkLineItemID: leaflinkLineItem?.id || null,
              };
            });

            //  Leaflink API automatically sets the order's payment term to the default Leaflink payment option.
            //
            //  So, if we need to place an order with a term other than the default payment option on Leaflink,
            //  we've been advised by the Leaflink Integrations Team to patch the order using
            //  `available_payment_options` from the response of the API call

            let paymentOption = externalOrder.available_payment_options.find(
              (option) =>
                this.rightDrawer.data.paymentTerm.value.replace(/\s/g, "") ===
                option.payment_term.code
            );
            if (paymentOption) {
              const patchLeafLinkOrder = httpsCallable(
                functions,
                "externalAPI"
              );
              const { patch } = await patchLeafLinkOrder({
                method: "PATCH",
                headers: {
                  Authorization: `App ${payableTo?.integrations.leaflink.api}`,
                },
                url: `${process.env.VUE_APP_LEAFLINK}api/v2/orders-received/${externalOrder.order_number}`,
                data: {
                  selected_payment_option: {
                    payment_method_id: 1,
                    payment_term_id: paymentOption.payment_term.id,
                  },
                },
              });
            }
          }

          // Line Items
          lineItems.forEach((lineItem) => {
            batch.set(
              doc(collection(firestore, "salesOrders_lineItems")),
              {
                created: serverTimestamp(),
                updated: serverTimestamp(),
                ...lineItem,
              },
              { merge: true }
            );

            // Add to reserved at location

            batch.set(
              locationRef,
              {
                manufacturing: {
                  [lineItem.categoryID]: {
                    reserved: {
                      [lineItem.productID]: increment(lineItem.qty),
                    },
                  },
                },
              },
              { merge: true }
            );
          });
          await batch.commit();
        } catch (error) {
          console.log(error);
          abort = true;
        }
      }

      // Delete Internal Order if need to abort
      this.loading.submit = false;
      this.$emit("cancel-submit");
      if (abort) {
        await deleteDoc(salesOrderDoc);
        // alert(
        //   `Error submitting order.\n\nPlease consider saving your order as a Draft Order and trying to submit again in 15 minutes.\n\nIf this error persists, please contact a ${this.getAppName} administrator.`
        // );
        this.errorDialog = true;
      } else {
        this.$router.push({
          name: "salesOrder-view",
          params: {
            id: salesOrderDoc.id,
          },
        });
      }
      this.contact = null;
      this.approvalConfirmation = null;
      this.shippingFee = 0;
      this.notes = "";
      this.internalNotes = "";
      this.deliveryPrefs = "";
      this.activeViews.approvalConfirmation = true;
    },
    async downloadInvoice() {
      this.loading.invoice = true;

      // Payable To
      const payableToRef = collection(firestore, "payableTo");
      const state = where(
          "location.state.stateID",
          "==",
          this.getRightDrawer.data.accountDetails.account.location.shipping
            .state.stateID
        ),
        brandID = where(
          "brandID",
          "==",
          this.getRightDrawer.data.accountDetails.brandStats.brand.id
        );
      const q = query(payableToRef, state, brandID);
      const response = await getDocs(q, limit(1));

      const selectedState = response.docs[0].data();
      let payableTo = response.docs[0].data();
      // Brand
      const brandResponse = await getDoc(
        doc(
          firestore,
          "companyBrands",
          this.getRightDrawer.data.accountDetails.brandStats.brand.id
        )
      );

      let brand = {
        id: brandResponse.id,
        ...brandResponse.data(),
      };

      let externalOrder =
        selectedState.flags.leaflink &&
        this.getRightDrawer.data.accountDetails.account.externalIDs.leaflink;

      const selectedAccount =
        this.getRightDrawer.data.accountDetails.brandStats;
      // const brand = this.getRightDrawer.data.accountDetails.brandStats;

      const externalOrderNumber = "-";
      const internalOrderNumber = "-";
      const invoice = new jsPDF({ format: "letter" });
      let date = moment().format("M/D/YYYY");
      const fileName = `${selectedAccount.account.name.replace(
        /(\b[a-z](?!\s))/g,
        (l) => l.toUpperCase()
      )} - Draft ${date}`;

      const pageWidth = invoice.internal.pageSize.getWidth();

      // Header

      if (brand.flags.hasLogo) {
        let logoRef = ref(storage, `brand/${brand.id}/logo`);

        const bytes = await getBytes(logoRef);
        const metadata = await getMetadata(logoRef);

        const img =
          "data:" +
          metadata.contentType +
          ";base64," +
          new Buffer.from(bytes).toString("base64");

        invoice.addImage(img, 94, 10, 35, 35, "logo", "FAST");
      } else {
        invoice.setFontSize(24);
        invoice.setFont("helvetica", "bold");
        let brandName = brand.name.replace(/\b\w/g, (l) => l.toUpperCase());
        invoice.text(brandName, 110, 30, "center");
      }

      // Customer
      invoice.setFontSize(14);
      invoice.setFont("helvetica", "bold");
      invoice.text("Store Information:", 10, 55);
      invoice.setFontSize(10);
      invoice.setFont("helvetica", "normal");
      const accountName =
        this.getRightDrawer.data.accountDetails.account.name.replace(
          /(\b[a-z](?!\s))/g,
          (l) => l.toUpperCase()
        );

      let phone = "";
      var cleaned = (
        "" + this.getRightDrawer.data.accountDetails.account.phone
      ).replace(/\D/g, "");
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        phone = "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      const phoneNumber = "\n" + phone;

      const license =
        "\n" +
        this.getRightDrawer.data.accountDetails.account.license.number.toUpperCase();

      //  the .splitTextToSize() method is able to wrap text based on the max-length
      //  given as the second param
      //    - this is necessary for our 3-column invoice format, as some account names
      //      are long enough to overlap the second column
      //    - using this method, we're able to join the account's name, phone number,
      //      and license number into one string and use line breaks to force them
      //      to their own lines
      //        - we have to do this to make the store information consistent regardless
      //          of the length of the account's name

      var wrappedStoreInformation = invoice.splitTextToSize(
        accountName + phoneNumber + license,
        53
      );
      invoice.text(wrappedStoreInformation, 10, 65);
      // Customer - Shipping Address
      const accountShippingAddress = `${
        this.getRightDrawer.data.accountDetails.account.location.shipping.street
          .number
      } ${this.getRightDrawer.data.accountDetails.account.location.shipping.street.name.replace(
        /\b\w/g,
        (l) => l.toUpperCase()
      )}`;
      const accountShippingCityZipCode = `${this.getRightDrawer.data.accountDetails.account.location.shipping.city.name.replace(
        /\b\w/g,
        (l) => l.toUpperCase()
      )}, ${this.getRightDrawer.data.accountDetails.account.location.shipping.state.abbreviation.toUpperCase()} ${
        this.getRightDrawer.data.accountDetails.account.location.shipping
          .zipCode
      }`;
      invoice.setFont("helvetica", "bold");
      invoice.setFontSize(12);
      invoice.text("Delivery Address:", 10, 85);
      invoice.setFontSize(10);
      invoice.setFont("helvetica", "normal");
      var wrappedShippingAddress = invoice.splitTextToSize(
        accountShippingAddress + `\n${accountShippingCityZipCode}`,
        53
      );
      invoice.text(wrappedShippingAddress, 10, 95);
      // Customer - Billing Address
      const accountBillingAddress = `${
        this.getRightDrawer.data.accountDetails.account.location.billing.street
          .number
      } ${this.getRightDrawer.data.accountDetails.account.location.billing.street.name.replace(
        /\b\w/g,
        (l) => l.toUpperCase()
      )}`;
      const accountBillingCityZipCode = `${this.getRightDrawer.data.accountDetails.account.location.billing.city.name.replace(
        /\b\w/g,
        (l) => l.toUpperCase()
      )}, ${this.getRightDrawer.data.accountDetails.account.location.billing.state.abbreviation.toUpperCase()} ${
        this.getRightDrawer.data.accountDetails.account.location.billing.zipCode
      }`;
      invoice.setFontSize(12);
      invoice.setFont("helvetica", "bold");
      invoice.text("Billing Address:", 10, 110);
      invoice.setFontSize(10);
      invoice.setFont("helvetica", "normal");
      if (this.getRightDrawer.data.accountDetails.account.flags.sameBilling) {
        invoice.text("Same as Delivery Address", 10, 120);
      } else {
        var wrappedBillingAddress = invoice.splitTextToSize(
          accountBillingAddress + `\n${accountBillingCityZipCode}`,
          53
        );
        invoice.text(wrappedBillingAddress, 10, 120);
      }
      if (this.contact) {
        // Buyer - Contact Information
        let contact = this.contact;
        let startY = this.getRightDrawer.data.accountDetails.account.flags
          .sameBilling
          ? 130
          : 135;
        invoice.setFontSize(12);
        invoice.setFont("helvetica", "bold");
        invoice.text("Buyer Information:", 10, startY);
        invoice.setFontSize(10);
        invoice.setFont("helvetica", "normal");
        let buyerName = contact.fullName.replace(/\b\w/g, (l) =>
          l.toUpperCase()
        );
        invoice.text(buyerName, 10, startY + 10);
        invoice.text(contact.email, 10, startY + 15);
      }

      // Vertical Divider
      invoice.setDrawColor("#F2F5F6");
      invoice.line(70, 65, 70, 135, "S");
      invoice.stroke();

      // Licensee
      invoice.setFontSize(14);
      invoice.setFont("helvetica", "bold");
      invoice.text("Licensee:", 80, 55);
      invoice.setFontSize(10);
      invoice.setFont("helvetica", "normal");
      const payableToName = payableTo.name.replace(/\b\w/g, (l) =>
        l.toUpperCase()
      );

      let payableToPhone = "";
      var cleaned = ("" + payableTo.phoneNumber).replace(/\D/g, "");
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        payableToPhone = "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      const payableToPhoneNumber = "\n" + payableToPhone;

      const payableToLicense = "\n" + payableTo.license.number.toUpperCase();

      var wrappedPayableToInformation = invoice.splitTextToSize(
        payableToName + payableToPhoneNumber + payableToLicense,
        50
      );
      invoice.text(wrappedPayableToInformation, 80, 65);
      // Payable To - Address
      const payableToStreetAddress =
        !payableTo.location.street?.name || !payableTo.location.street?.number
          ? "[No Address Set]"
          : `${
              payableTo.location.street.number
            } ${payableTo.location.street.name.replace(/\b\w/g, (l) =>
              l.toUpperCase()
            )}`;
      invoice.setFont("helvetica", "bold");
      invoice.setFontSize(12);
      invoice.text("Address:", 80, 85);
      invoice.setFontSize(10);
      invoice.setFont("helvetica", "normal");

      let payableToAddress = `${payableToStreetAddress}\n${payableTo.location.city.replace(
        /\b\w/g,
        (l) => l.toUpperCase()
      )}, ${payableTo.location.state.abbreviation.toUpperCase()} ${
        payableTo.location.zipCode
      }`;

      var wrappedPayableToAddress = invoice.splitTextToSize(
        payableToAddress,
        50
      );
      invoice.text(wrappedPayableToAddress, 80, 95);

      // Vertical Divider
      invoice.setDrawColor("#F2F5F6");
      invoice.line(135, 65, 135, 135, "S");
      invoice.stroke();

      // Overview
      invoice.setFontSize(14);
      invoice.setFont("helvetica", "bold");
      invoice.text("Brand:", 145, 55);
      invoice.setFont("helvetica", "normal");
      invoice.setFontSize(10);
      invoice.text("Payment Term", 145, 65);

      let paymentTerm = this.getRightDrawer.data.paymentTerm;
      invoice.text(paymentTerm.text, 205, 65, {
        align: "right",
      });

      invoice.text("External Invoice", 145, 70);
      invoice.setFont("helvetica", "normal");

      invoice.text(externalOrderNumber, 205, 70, {
        align: "right",
      });
      invoice.text("Ship Date", 145, 75);
      invoice.setFont("helvetica", "normal");
      invoice.text("-", 205, 75, {
        align: "right",
      });
      invoice.text("Created", 145, 80);
      invoice.setFont("helvetica", "normal");
      invoice.text(moment().format("MMM DD, YYYY"), 205, 80, {
        align: "right",
      });
      invoice.setFont("helvetica", "normal");
      invoice.text("Created By", 145, 85);
      invoice.setFont("helvetica", "normal");
      const createdByName =
        this.getUser.name.first + " " + this.getUser.name.last;
      invoice.text(
        this.firstNameWithLastInitialFromValue(createdByName),
        205,
        85,
        {
          align: "right",
        }
      );
      invoice.setFontSize(14);
      invoice.setFont("helvetica", "bold");
      invoice.text("Account:", 145, 95);
      invoice.setFontSize(10);
      invoice.setFont("helvetica", "normal");

      let hasExecutive =
        selectedAccount.staff.executive.id &&
        selectedAccount.staff.executive.id.length > 0;
      let hasManager =
        selectedAccount.staff.manager.id &&
        selectedAccount.staff.manager.id.length > 0;

      if (hasExecutive) {
        const userDoc = doc(
          firestore,
          "users",
          selectedAccount.staff.executive.id
        );
        const response = await getDoc(userDoc);

        let executivePhoneNumber = "-";

        if (response.exists()) {
          executivePhoneNumber = response.data().phoneNumber
            ? this.$options.filters.phoneNumber(response.data().phoneNumber)
            : "-";
        }

        invoice.text("Executive", 145, 105);
        invoice.setFont("helvetica", "normal");
        invoice.text(
          this.firstNameWithLastInitialFromValue(
            selectedAccount.staff.executive.name
          ),
          205,
          105,
          {
            align: "right",
          }
        );
        invoice.text(executivePhoneNumber, 205, 110, {
          align: "right",
        });
        invoice.text(selectedAccount.staff.executive.email, 205, 115, {
          align: "right",
        });
      }
      invoice.setFont("helvetica", "normal");
      if (hasManager) {
        const userDoc = doc(
          firestore,
          "users",
          selectedAccount.staff.manager.id
        );
        const response = await getDoc(userDoc);

        let managerPhoneNumber = "-";

        if (response.exists()) {
          managerPhoneNumber = response.data().phoneNumber
            ? this.$options.filters.phoneNumber(response.data().phoneNumber)
            : "-";
        }

        invoice.text("Manager", 145, hasExecutive ? 125 : 105);
        invoice.setFont("helvetica", "normal");
        invoice.text(
          this.firstNameWithLastInitialFromValue(
            selectedAccount.staff.manager.name
          ),
          205,
          hasExecutive ? 125 : 105,
          {
            align: "right",
          }
        );
        invoice.text(managerPhoneNumber, 205, hasExecutive ? 130 : 110, {
          align: "right",
        });
        invoice.text(
          selectedAccount.staff.manager.email,
          205,
          hasExecutive ? 135 : 115,
          {
            align: "right",
          }
        );
      }

      // Divider
      let productsTableDividerY =
        !this.getRightDrawer.data.accountDetails.account.flags.sameBilling &&
        this.contact
          ? 160
          : 150;

      invoice.setDrawColor("#F2F5F6");
      invoice.line(10, productsTableDividerY, 205, productsTableDividerY, "S");
      invoice.stroke();
      //   Products
      invoice.setFontSize(14);
      invoice.setFont("helvetica", "bold");
      invoice.text("Products:", 10, productsTableDividerY + 10);

      let addedProducts = this.rightDrawer.data.products.filter(
        (item) => item.qty.case > 0 || item.qty.single > 0
      );

      let products = addedProducts
        .filter((item) => !item.promo)
        .sort((a, b) => {
          if (a.category > b.category) {
            return 1;
          } else if (a.category === b.category && a.name > b.name) {
            return 1;
          } else if (a.category === b.category && a.name === b.name) {
            return 0;
          } else if (a.category === b.category && a.name < b.name) {
            return -1;
          } else if (a.category < b.category) {
            return -1;
          }
        })
        .map((item) => {
          let units = item.qty.case * item.caseQTY + item.qty.single;

          let productName = this.$options.filters.capitalize(item.name);
          let categoryName = this.$options.filters.capitalize(item.category);
          return [
            `${productName}\n${categoryName}`,
            // `${item.sku.toUpperCase()}`,
            `                 `,
            `${this.orderType === "sample" ? "Yes" : "No"}`,
            this.$options.filters.addComma(item.qty.case),
            this.$options.filters.addComma(item.qty.single),
            this.$options.filters.addComma(units),
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "usd",
            }).format(item.price),
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "usd",
            }).format(units * item.price),
          ];
        });

      let promoUnitProducts = addedProducts.filter((item) => item.promo);

      if (promoUnitProducts.length > 0) {
        promoUnitProducts = promoUnitProducts.sort((a, b) => {
          if (a.category > b.category) {
            return 1;
          } else if (a.category === b.category && a.name > b.name) {
            return 1;
          } else if (a.category === b.category && a.name === b.name) {
            return 0;
          } else if (a.category === b.category && a.name < b.name) {
            return -1;
          } else if (a.category < b.category) {
            return -1;
          }
        });
        products.push([
          {
            content: "",
            colSpan: 8,
            styles: { halign: "center", fillColor: 255 },
          },
        ]);
        products.push([
          {
            content: "PROMO UNITS",
            colSpan: 8,
            styles: {
              halign: "center",
              fillColor: 255,
              textColor: 0,
              fontStyle: "bold",
            },
          },
        ]);
        promoUnitProducts.forEach((item) => {
          let units = item.qty.case * item.caseQTY + item.qty.single;

          let productName = this.$options.filters.capitalize(item.name);
          let categoryName = this.$options.filters.capitalize(item.category);
          products.push([
            `${productName}\n${categoryName}`,
            // `${item.sku.toUpperCase()}`,
            `                 `,
            `${this.orderType === "sample" ? "Yes" : "No"}`,
            this.$options.filters.addComma(item.qty.case),
            this.$options.filters.addComma(item.qty.single),
            this.$options.filters.addComma(units),
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "usd",
            }).format(item.price),
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "usd",
            }).format(units * item.price),
          ]);
        });
      }
      invoice.autoTable({
        head: [
          [
            "Item",
            // "SKU",
            "Notes",
            "Sample?",
            "Cases",
            "Singles",
            "Units",
            "Price",
            "Total",
          ],
        ],
        body: products,
        startY: productsTableDividerY + 15,
        margin: {
          right: 10,
          left: 10,
          bottom: 15,
          top: 20,
        },
        theme: "striped",
        headStyles: {
          fillColor: "black",
          textColor: "white",
        },
      });

      //  We need to check the `pageNumber` property of the products table to see if it
      //    overflowed past page 1.
      //
      //    - If it did, jspdf's `previousAutoTable` has a `finalY`
      //    property that we can add to the y values of all of the content after the
      //    product's table to place the content below the products table correctly.
      //
      //    - If the table did not overflow , we set this value to 0 as we
      //    do not have to modify the position of anything on the second page

      if (
        invoice.previousAutoTable.pageNumber === 1 ||
        invoice.previousAutoTable.finalY > 160
      ) {
        invoice.addPage();
        invoice.setPage(
          invoice.previousAutoTable.finalY > 160
            ? invoice.previousAutoTable.pageNumber + 1
            : invoice.previousAutoTable.pageNumber
        );
      }

      const productsTableFinalY =
        invoice.previousAutoTable.pageNumber === 1 ||
        invoice.previousAutoTable.finalY > 160
          ? 0
          : invoice.previousAutoTable.finalY;

      // Notes
      invoice.setFont("helvetica", "bold");
      invoice.setFontSize(12);
      invoice.text(10, 25 + productsTableFinalY, `Notes:`);
      invoice.setFont("helvetica", "italic");
      invoice.setFontSize(10);

      var wrappedNotesForDimensions = invoice.splitTextToSize(this.notes, 100);
      let notesDimensions = invoice.getTextDimensions(
        wrappedNotesForDimensions
      );
      let notesSubstring =
        this.notes.length < 600 && notesDimensions.h <= 52.5
          ? this.notes
          : this.notes.substr(0, 600) + "...";

      var wrappedNotes = invoice.splitTextToSize(notesSubstring, 100);
      invoice.text(wrappedNotes, 10, 35 + productsTableFinalY);

      // Delivery Preferences
      invoice.setFont("helvetica", "bold");
      invoice.setFontSize(12);
      invoice.text(10, 85 + productsTableFinalY, `Delivery Preferences:`);
      invoice.setFont("helvetica", "italic");
      invoice.setFontSize(10);

      var wrappedDeliveryPreferences = invoice.splitTextToSize(
        this.getRightDrawer.data.accountDetails.account.deliveryPreferences
          .notes,
        pageWidth - 20
      );
      invoice.text(wrappedDeliveryPreferences, 10, 95 + productsTableFinalY);

      // Balance
      invoice.setFont("helvetica", "normal");
      invoice.text(
        `Subtotal (Qty: ${this.$options.filters.addComma(this.totalUnits)})`,
        125,
        25 + productsTableFinalY
      );
      invoice.text(
        Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "usd",
        }).format(this.orderTotal - this.shippingFee),
        205,
        25 + productsTableFinalY,
        {
          align: "right",
        }
      );
      // invoice.setDrawColor("#E5E7EB");
      // invoice.line(115, 27, 200, 27, "S");
      // invoice.stroke();
      // invoice.text("Excise Tax", 120, 40);
      // invoice.text(
      //   Intl.NumberFormat("en-US", {
      //     style: "currency",
      //     currency: "usd",
      //   }).format(this.exciseTax),
      //   200,
      //   35,
      //   {
      //     align: "right",
      //   }
      // );
      invoice.setDrawColor("#E5E7EB");
      invoice.line(
        120,
        27 + productsTableFinalY,
        205,
        27 + productsTableFinalY,
        "S"
      );
      invoice.stroke();
      invoice.text("Shipping", 125, 35 + productsTableFinalY);
      invoice.text(
        Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "usd",
        }).format(this.shippingFee),
        205,
        35 + productsTableFinalY,
        {
          align: "right",
        }
      );
      invoice.setDrawColor("#E5E7EB");
      invoice.line(
        120,
        37 + productsTableFinalY,
        205,
        37 + productsTableFinalY,
        "S"
      );
      invoice.stroke();
      // invoice.text("Payments Received", 125, 45 + productsTableFinalY);
      // invoice.text(
      //   Intl.NumberFormat("en-US", {
      //     style: "currency",
      //     currency: "usd",
      //   }).format(0),
      //   205,
      //   45 + productsTableFinalY,
      //   {
      //     align: "right",
      //   }
      // );
      // invoice.setDrawColor("#E5E7EB");
      // invoice.line(
      //   120,
      //   47 + productsTableFinalY,
      //   205,
      //   47 + productsTableFinalY,
      //   "S"
      // );
      // invoice.stroke();

      // Total
      invoice.setFont("helvetica", "normal");
      invoice.setFontSize(10);
      invoice.text("Total", 125, 45 + productsTableFinalY);
      invoice.text(
        Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "usd",
        }).format(this.orderTotal),
        205,
        45 + productsTableFinalY,
        {
          align: "right",
        }
      );

      // Divider
      // invoice.setDrawColor("#F2F5F6");
      // invoice.line(
      //   10,
      //   75 + productsTableFinalY,
      //   205,
      //   75 + productsTableFinalY,
      //   "S"
      // );
      // invoice.stroke();

      // Account Balance Rect
      // invoice.setDrawColor(0);
      // invoice.setFillColor("#F2F5F6");
      // invoice.roundedRect(
      //   122.5,
      //   70 + productsTableFinalY,
      //   85,
      //   18.5,
      //   2,
      //   2,
      //   "FD"
      // );

      // END
      var pageCount = invoice.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        invoice.setPage(i);
        invoice.setFontSize(14);
        invoice.setFont("helvetica", "bold");
        invoice.text("invoice - draft".toUpperCase(), 10, 10);
        invoice.setFont("helvetica", "normal");
        invoice.setFontSize(10);
        invoice.text(10, 15, `This order is pending submission.`);
        invoice.setFont("helvetica", "normal");
        invoice.setFontSize(10);
        invoice.text(
          110,
          270,
          `Page ${
            invoice.internal.getCurrentPageInfo().pageNumber
          } / ${pageCount}`,
          {
            align: "center",
          }
        );

        // Powered by MyTrace
        var img = new Image();
        img.src = "/img/myTrace_fullLogo.png";
        invoice.addImage(
          img,
          "png",
          pageWidth - 30,
          4.5,
          20,
          9,
          "mytraceLogo",
          "FAST"
        );
        invoice.setFont("helvetica", "normal");
        invoice.setFontSize(7);
        invoice.text(pageWidth - 31, 10, `Powered by`, {
          align: "right",
        });
      }

      // invoice.output("dataurlnewwindow");      //  Opens in a new tab, doesn't work on iOS/Safari
      invoice.save(`${fileName}.pdf`);
      this.loading.invoice = false;
    },
    /* Main */
    async setup() {
      this.loading.submit = false;
      this.contact = null;
      this.approvalConfirmation = null;
      this.shippingFee = 0;
      this.selectedPaymentTerm = 0;

      this.deliveryPrefs =
        this.getRightDrawer.data?.accountDetails?.account?.deliveryPreferences
          ?.notes ?? "";
      if (this.getRightDrawer.data.accountDetails.brandStats.metrics.balance) {
        this.activeViews.approvalConfirmation = true;
      }
    },
    removeItem(item) {
      const index = this.rightDrawer.data.products.indexOf(item);
      if (index > -1) {
        this.rightDrawer.data.products.splice(index, 1);
      }
      if (!this.rightDrawer.data.products.length) this.$emit("cancel-edit");
    },
    async openPrice(item) {
      if (item.promo || this.orderType === "sample" || !this.editMode) return;
      item.menu.price = true;
      item.newPrice = this.$options.filters.currency(item.price).substring(1);
      let element = this.$refs[this.refForItem(item)];
      this.$nextTick(() => {
        if (element) {
          setTimeout(() => {
            element?.$el?.focus();
            element?.$refs?.input?.select();
          }, 250);
        }
      });
    },
    openQTY(item) {
      if (!this.editMode) return;
      item.newQTY = item.totalQTY;
      item.menu.qty = true;
    },
    closePrice(item, type) {
      switch (type) {
        case "save":
          let price =
            Number(item.newPrice) > 0.01
              ? Number(item.newPrice)
              : Number(item.price);
          item.price = price;
          item.newPrice = item.price;
          item.menu.price = false;
          break;
        case "cancel":
          item.newPrice = Number(item.price);
          item.menu.price = false;
          break;
      }
    },
    refForItem(item) {
      return `priceInput_${item.id}${item.promo ? "_promo" : ""}`;
    },
    handleProductQTYChange(item, val) {
      if (val === null) {
        item.unitWindow = 1;
        item.newQTY = item.totalQTY;
        return;
      }
      let start = val;
      let startAmount = Math.min(start, this.availableQTYForItem(item));
      if (this.orderType === "sample") {
        item.qty.single = startAmount;
        item.totalQTY = startAmount;
        item.unitWindow = 1;
        return;
      }
      item.totalQTY = startAmount;
      item.qty.case = Math.floor(startAmount / item.caseQTY);
      startAmount %= item.caseQTY;
      item.qty.single = startAmount;
      item.unitWindow = 1;
    },
    decrement(type, item) {
      let idx = _.findIndex(this.rightDrawer.data.products, item);
      switch (type) {
        case "single":
          if ((item.soldByCase || item.caseQTY) && this.orderType === "sales") {
            if (item.qty.single == 0) {
              //  Set single qty to caseQTY - 1, decrement case qty by 1
              item.qty.single = item.caseQTY - 1;
              item.qty.case -= 1;
            } else {
              item.qty.single -= 1;
            }
          } else {
            item.qty.single -= 1;
          }
          break;
        case "case":
          item.qty.case -= 1;
          break;
      }
      item.totalQTY = item.qty.single + item.qty.case * item.caseQTY;
      this.rightDrawer.data.products.splice(idx, 1, item);
    },
    increment(type, item) {
      let idx = _.findIndex(this.rightDrawer.data.products, item);
      switch (type) {
        case "single":
          if ((item.soldByCase || item.caseQTY) && this.orderType === "sales") {
            if (item.qty.single == item.caseQTY - 1) {
              //  Reset single qty to 0, increment case qty by 1
              item.qty.single = 0;
              item.qty.case += 1;
            } else {
              item.qty.single += 1;
            }
          } else {
            item.qty.single += 1;
          }
          break;
        case "case":
          item.qty.case += 1;
          break;
      }
      item.totalQTY = item.qty.single + item.qty.case * item.caseQTY;
      this.rightDrawer.data.products.splice(idx, 1, item);
    },
    availableQTYForItem(item) {
      let totalInCart = this.rightDrawer.data.products
        .filter(
          (product) => product.id === item.id && product.promo !== item.promo
        )
        .reduce(
          (sum, product) =>
            sum + product.qty.single + product.qty.case * product.caseQTY,
          0
        );
      return item.available - totalInCart;
    },
    clearCart() {
      this.rightDrawer.data.products.splice(0);
    },
    enableSubmit() {
      this.$emit("disable-submit-button", false);
    },
    disableSubmit() {
      this.$emit("disable-submit-button", true);
    },
    firstNameWithLastInitialFromValue(name) {
      if (name.length > 0) {
        let capitalizedName = name
          .split(" ")
          .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`);
        return `${capitalizedName[0]} ${capitalizedName[1].charAt(0)}.`;
      } else {
        return "[Name Not Set]";
      }
    },
    async handleContactSubmit(submit = false) {
      if (submit) {
        this.loading.submit = true;
        let batch = writeBatch(firestore);

        const contactDoc = doc(collection(firestore, "contacts"));

        let location = null;
        let externalIDs = {};
        let unitNumber = "";
        if (
          this.newContact.location.address_components.find((component) =>
            component.types.includes("subpremise")
          )
        ) {
          unitNumber = this.newContact.location.address_components.find(
            (component) => component.types.includes("subpremise")
          ).long_name;
        }
        const streetNumber = this.newContact.location.address_components
            .find((component) => component.types.includes("street_number"))
            .long_name.toLowerCase(),
          street = this.newContact.location.address_components
            .find((component) => component.types.includes("route"))
            .long_name.toLowerCase(),
          city = this.newContact.location.address_components
            .find((component) => component.types.includes("locality"))
            .long_name.toLowerCase(),
          state = this.newContact.location.address_components
            .find((component) =>
              component.types.includes("administrative_area_level_1")
            )
            .long_name.toLowerCase(),
          stateAbbreviation = this.newContact.location.address_components
            .find((component) =>
              component.types.includes("administrative_area_level_1")
            )
            .short_name.toLowerCase(),
          country = this.newContact.location.address_components
            .find((component) => component.types.includes("country"))
            .long_name.toLowerCase(),
          zipCode = this.newContact.location.address_components
            .find((component) => component.types.includes("postal_code"))
            .long_name.toLowerCase();

        location = {
          street: {
            number: streetNumber,
            name: street,
          },
          city,
          state: {
            name: state,
            abbreviation: stateAbbreviation,
          },
          country,
          zipCode,
          unitNumber,
        };
        externalIDs = {
          google_places: this.newContact.location.place_id,
        };

        batch.set(
          contactDoc,
          {
            active: true,
            created: serverTimestamp(),
            updated: serverTimestamp(),
            createdBy: {
              name: this.getUser.name,
              uid: this.getUser.uid,
            },
            lastUpdated: {
              name: this.getUser.name,
              uid: this.getUser.uid,
            },
            related: {
              id: this.rightDrawer.data.accountDetails.account.id,
              name: this.rightDrawer.data.accountDetails.account.name,
              collection: "accounts",
            },
            phase: "sales",
            relatedID: this.rightDrawer.data.accountDetails.account.id,
            relatedTo: "accounts",
            name: {
              first: this.newContact.name.first.toLowerCase(),
              last: this.newContact.name.last.toLowerCase(),
            },
            externalIDs,
            location,
            email: this.newContact.email.toLowerCase(),
            phoneNumber: this.newContact.phoneNumber?.length
              ? this.cleanedPhoneNumber(this.newContact.phoneNumber)
              : "",
            title: this.newContact.title.value,
            contactPreference: this.newContact.contactPreference || "",
            notes: this.newContact.notes,
            phase: "sales",
            phaseView: "accounts",
          },
          { merge: true }
        );

        batch.set(
          doc(collection(firestore, "ledger")),
          {
            created: serverTimestamp(),
            date: serverTimestamp(),
            relatedID: contactDoc.id,
            relatedTo: "contacts",
            type: "status",
            action: `<span class="text-capitalize">${this.getUser.name.first} ${this.getUser.name.last}</span> created <span class="font-weight-bold text-capitalize">${this.newContact.name.first} ${this.newContact.name.last}</span> as an <span class="text-uppercase font-weight-bold" style="color: ${this.$vuetify.theme.themes.dark.success};">active</span> contact`,
            user: {
              name: this.getUser.name,
              uid: this.getUser.uid,
            },
          },
          { merge: true }
        );
        await batch.commit();

        let item = { ...this.newContact };

        item.expanded = false;
        item.fullName = `${this.newContact.name.first} ${this.newContact.name.last}`;
        item.isParentContact = false;
        item.selected = false;
        item.text = item.fullName;
        item.id = contactDoc.id;
        item.value = item.id;

        this.rightDrawer.data.contacts.splice(0, 0, item);
        this.loading.submit = false;
      }
      this.contactDialog = false;
      this.resetNewContact();
    },
    resetNewContact() {
      this.newContact = {
        name: {
          first: "",
          last: "",
        },
        phoneNumber: "",
        email: "",
        address: null,
      };
    },
    async openContactDialog() {
      let account = this.rightDrawer.data.accountDetails.account.location;
      this.newContact.locationInput = this.fullAddressFromAccount(account);
      this.contactDialog = true;
    },
    async setupGoogleAPI() {
      const google = await gmapsInit();
      this.google = google.maps;

      const geocoder = new this.google.Geocoder();
      this.geocoder = geocoder;
    },
    async placeSearch(payload) {
      if (payload) {
        const { results } = await this.geocoder.geocode({
          address: payload.text,
        });

        this.newContact.location = results[0];
        this.newContact.locationInput = results[0].formatted_address;
      } else {
        this.newContact.locationInput = "";
        this.newContact.location = null;
      }
    },
    fullAddressFromAccount(account) {
      let streetNumber = account.shipping.street.number || "";
      let streetName =
        account.shipping.street.name.replace(/\b\w/g, (l) => l.toUpperCase()) ||
        "";
      let city =
        account.shipping.city.name.replace(/\b\w/g, (l) => l.toUpperCase()) ||
        "";
      let state = account.shipping.state.abbreviation.toUpperCase() || "";
      let zipCode = account.shipping.zipCode || "";

      return `${streetNumber} ${streetName}, ${city}, ${state}  ${zipCode}`;
    },
    totalPriceForTerm(term) {
      let result = this.products.reduce((sum, product) => {
        let hasVariablePricing =
          product.hasVariablePricing &&
          product.variablePrice?.find((item) => item.term === term.value);
        let price = product.price;
        if (hasVariablePricing) {
          price =
            product.variablePrice?.find((item) => item.term === term.value)
              ?.price ?? product.price;
        }
        let totalQTY = product.qty.single + product.qty.case * product.caseQTY;
        return sum + price * totalQTY;
      }, 0);
      return result;
    },
    updatePricing() {
      this.rightDrawer.data.products.forEach((product) => {
        let foundPrice = product.variablePrice?.find(
          (item) => item.term === this.paymentTerm.value
        );
        if (foundPrice) {
          product.price = foundPrice.price;
          product.originalPrice = foundPrice.price;
        }
      });
    },
  },
  computed: {
    ...mapGetters([
      "getAppName",
      "getUser",
      "getRightDrawer",
      "getContactTitles",
      "getAccountStatus",
      "getPaymentTerms",
    ]),
    rightDrawer: {
      get: function () {
        return this.getRightDrawer;
      },
      set: function (value) {
        this._rightDrawer({
          ...this.getRightDrawer,
          active: value,
        });
      },
    },
    productsOnSale() {
      return this.rightDrawer.data.products.some(
        (item) => item.price < item.originalPrice && !item.promo
      );
    },
    accountStatusNeedsApproval() {
      return true;
    },
    orderNeedsApproval() {
      return (
        this.accountStatusNeedsApproval ||
        this.productsOnSale ||
        this.accountHasBalance
      );
    },
    productsTotal() {
      return this.rightDrawer.data.products.reduce(
        (sum, item) =>
          sum + item.price * (item.qty.single + item.qty.case * item.caseQTY),
        0
      );
    },
    paymentTerms() {
      return this.getPaymentTerms.filter((item) =>
        ["cod", "net 30"].includes(item.value)
      );
    },
    paymentTerm() {
      return this.paymentTerms[this.selectedPaymentTerm];
    },
    netTotalsForProducts() {
      let terms = this.paymentTerms;

      return terms.map((term) => {
        return {
          text: term.text,
          value: term.value,
          amount: this.totalPriceForTerm(term),
        };
      });
    },
    netTotalForProducts() {
      return this.rightDrawer.data?.products?.reduce((sum, item) => {
        let price =
          item.hasVariablePricing &&
          item.variablePrice?.find((price) => price.term === "net 30")
            ? item.variablePrice?.find((price) => price.term === "net 30").price
            : item.price;
        return sum + price * (item.qty.single + item.qty.case * item.caseQTY);
      }, 0);
    },
    orderTotal() {
      let selectedTerm = this.netTotalsForProducts[this.selectedPaymentTerm];
      // let productsTotal = this.totalPriceForTerm(selectedTerm);
      return Number(parseFloat(selectedTerm.amount).toFixed(2));
    },
    totalUnits() {
      return this.rightDrawer.data.products.reduce(
        (sum, item) => sum + (item.qty.single + item.qty.case * item.caseQTY),
        0
      );
    },
    promoPercentage() {
      let total = this.getRightDrawer.data?.products
        .filter((item) => !item.promo)
        .reduce(
          (sum, item) =>
            sum + item.price * (item.qty.single + item.qty.case * item.caseQTY),
          0
        );
      let promo = this.getRightDrawer.data?.products
        .filter((item) => item.promo)
        .reduce(
          (sum, item) =>
            sum +
            item.originalPrice *
              (item.qty.single + item.qty.case * item.caseQTY),
          0
        );

      return (promo / total) * 100;
    },
    accountHasBalance() {
      return (
        this.getRightDrawer.data.accountDetails.brandStats.metrics.balance > 0
      );
    },
    orderNotesSet() {
      let required = this.accountHasBalance;
      let notesSet = this.notes.length > 0;
      return (required && notesSet) || !required;
    },
    contactSet() {
      return this.contact !== null;
    },
    approvalConfirmationSet() {
      return (
        (this.orderNeedsApproval && this.approvalConfirmation) ||
        !this.orderNeedsApproval
      );
    },
    invalidSubmission() {
      let result =
        this.orderNotesSet && this.contactSet && this.approvalConfirmationSet
          ? false
          : true;

      this.rightDrawer.data.disableSubmission = result;
      return result;
    },
    isDownloading() {
      return this.loading.invoice;
    },
    // Contact Submit
    newContactHasEmailOrPhone() {
      return (
        this.newContact.phoneNumber?.length || this.newContact.email?.length
      );
    },
    validContact() {
      return (
        this.newContact.name.first?.length &&
        this.newContact.name.last?.length &&
        this.newContact.location &&
        this.newContactHasEmailOrPhone
      );
    },
  },
};
</script>

<style>
.softShadow {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}
</style>
