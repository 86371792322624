import Vue from "vue";
import VueRouter from "vue-router";
import { auth } from "@/firebase/init";

import store from "@/store/index.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/",
  },
  {
    path: "/403",
    name: "403",
    component: () => import("@/components/NotAllowed.vue"),
  },
  {
    path: "/",
    name: "home",
    component: () => import("@/views/system/HomeView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/system/LoginView.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  // Contacts
  {
    path: "/contacts/:relatedID/new",
    name: "contacts-new",
    component: () => import("@/components/contacts/Contact_New.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contacts/:id",
    name: "contacts-view",
    component: () => import("@/components/contacts/Contact_View.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  /* Admin */
  // Users
  {
    path: "/users/overview",
    name: "users-overview",
    component: () => import("@/views/phases/0_admin/users/users_overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/user/new",
    name: "user-new",
    component: () => import("@/views/phases/0_admin/users/user_new.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/user/:id",
    name: "user-view",
    component: () => import("@/views/phases/0_admin/users/user_view.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/:id",
    name: "profile-view",
    component: () => import("@/components/misc/UserProfile.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // Operating Zones
  {
    path: "/operatingZones/overview",
    name: "operatingZones-overview",
    component: () =>
      import(
        "@/views/phases/0_admin/operatingZones/operatingzones_overview.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/country/new",
    name: "country-new",
    component: () =>
      import("@/views/phases/0_admin/operatingZones/countries/country_new.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/country/:id",
    name: "country-view",
    component: () =>
      import(
        "@/views/phases/0_admin/operatingZones/countries/country_view.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/state/new",
    name: "state-new",
    component: () =>
      import("@/views/phases/0_admin/operatingZones/states/state_new.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/state/:id",
    name: "state-view",
    component: () =>
      import("@/views/phases/0_admin/operatingZones/states/state_view.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // Products
  {
    path: "/products/overview",
    name: "products-overview",
    component: () =>
      import("@/views/phases/0_admin/products/products_overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/product/new",
    name: "product-new",
    component: () => import("@/views/phases/0_admin/products/product_new.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/product/:id",
    name: "product-view",
    component: () => import("@/views/phases/0_admin/products/product_view.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  /* Business */
  // Clients
  {
    path: "/accounts/overview",
    name: "accounts-overview",
    component: () =>
      import("@/views/phases/1_clients/accounts/accounts_overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/account/new",
    name: "accounts-new",
    component: () =>
      import("@/views/phases/1_clients/accounts/account_new.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/account/:id",
    name: "accounts-view",
    component: () =>
      import("@/views/phases/1_clients/accounts/account_view.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // Demos
  {
    path: "/demos/overview",
    name: "demos-overview",
    component: () =>
      import("@/views/phases/1_clients/demos/demos_overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/demo/new",
    name: "demos-new",
    component: () =>
      import("@/views/phases/1_clients/demos/demo_new.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/demo/:id",
    name: "demos-view",
    component: () =>
      import("@/views/phases/1_clients/demos/demo_view.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // Tasks
  {
    path: "/tasks/overview",
    name: "tasks",
    component: () => import("@/views/phases/0_admin/tasks/tasks_overview.vue"),
  },
  {
    path: "/task/new",
    name: "tasks-new",
    component: () => import("@/views/phases/0_admin/tasks/task_new.vue"),
  },
  {
    path: "/task/:id",
    name: "tasks-view",
    component: () => import("@/views/phases/0_admin/tasks/task_view.vue"),
  },
  // Invoices
  {
    path: "/invoices/overview",
    name: "invoices-overview",
    component: () =>
      import("@/views/phases/1_clients/invoices/invoices_overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/invoice/:id/new",
    name: "invoices-new",
    component: () =>
      import("@/views/phases/1_clients/invoices/invoice_new.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/invoice/:id",
    name: "invoices-view",
    component: () =>
      import("@/views/phases/1_clients/invoices/invoice_view.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  /* Storefronts */
  // Companies
  {
    path: "/corporateEntities/overview",
    name: "corporateEntities-overview",
    component: () =>
      import(
        "@/views/phases/2_storefronts/corporateEntities/corporateEntities_overview.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/corporateEntities/new",
    name: "corporateEntities-new",
    component: () =>
      import(
        "@/views/phases/2_storefronts/corporateEntities/corporateEntities_new.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/corporateEntities/:id",
    name: "corporateEntities-view",
    component: () =>
      import(
        "@/views/phases/2_storefronts/corporateEntities/corporateEntities_view.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  // Stores
  {
    path: "/stores/overview",
    name: "stores-overview",
    component: () =>
      import("@/views/phases/2_storefronts/stores/stores_overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/store/:id/new",
    name: "store-new",
    component: () =>
      import("@/views/phases/2_storefronts/stores/store_new.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/store/:id",
    name: "store-view",
    component: () =>
      import("@/views/phases/2_storefronts/stores/store_view.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // Reports
  {
    path: "/reporting/overview",
    name: "reports-overview",
    component: () => import("@/views/phases/5_reporting/Reports_Overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/business/demos",
    name: "business-demos-report",
    component: () =>
      import(
        "@/views/phases/5_reporting/1_business/Business_Demos_Report.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/storefronts/stores",
    name: "storefronts-stores-report",
    component: () =>
      import(
        "@/views/phases/5_reporting/2_storefronts/Storefronts_Stores_Report.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (auth.currentUser) {
      let userViews = store.getters.getViews
        .filter((phaseView) =>
          store.getters.getUser.views.includes(phaseView.value)
        )
        .flatMap((phaseView) => phaseView.views);

      let phaseRoutes = store.getters.getPhases
        .filter(
          (phase) =>
            phase.route !== null &&
            store.getters.getUser.views.includes(phase.routeValue)
        )
        .flatMap((phase) => phase.views);

      let defaultViews = ["home", "profile-view"];

      let authorizedViews = userViews.concat(phaseRoutes).concat(defaultViews);
      if (
        store.getters.getUser.accountType >= 4 ||
        authorizedViews.includes(to.name)
      ) {
        next();
      } else {
        next({
          name: "403",
        });
      }
    } else {
      next({
        path: "/login",
      });
    }
  } else {
    next();
  }
});

export default router;
