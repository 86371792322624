/* eslint-disable no-console */
import { register } from "register-service-worker";

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered(registration) {
      console.log(
        "Service worker has been registered and is now polling for updates.."
      );
      setInterval(() => {
        registration.update();
      }, 1000 * 10); // every 10 seconds
    },
    cached(registration) {
      console.log("Content has been cached for offline use.");
      // document.dispatchEvent(
      //   new CustomEvent("swUpdateCached", { detail: registration })
      // );
      window.location.reload();
    },
    updatefound(registration) {
      console.log("New content is downloading.");
      document.dispatchEvent(
        new CustomEvent("swUpdateFound", { detail: registration })
      );
    },
    updated(registration) {
      console.log("New content is available; please refresh.");
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
      if (registration.waiting) {
        registration.waiting.postMessage({ type: "SKIP_WAITING" });
      }
      document.dispatchEvent(
        new CustomEvent("swUpdated", { detail: registration })
      );
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}
