import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-inline justify-center align-center"},[_c('span',{staticClass:"d-flex justify-center align-center",staticStyle:{"position":"relative"}},[_c('span',{staticClass:"d-flex align-center justify-center pa-0 font-weight-bold",style:(`
        width: ${_vm.size}px;
        height: ${_vm.size}px;
        color: white;
        border-radius: 50%;
        position: absolute;
        line-height: ${_vm.size}px;
        right: -20px;`)},[_c(VIcon,{style:(`font-size: ${_vm.iconSize}px`),attrs:{"color":"grey"}},[_vm._v(" "+_vm._s(_vm.iconText)+" ")])],1),_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }