const state = {
  reports: [
    {
      text: "demos",
      to: "business-demos-report",
      desc: "",
      icon: "mdi-projector-screen",
      type: "business",
    },
    {
      text: "stores",
      to: "storefronts-stores-report",
      desc: "",
      icon: "mdi-store",
      type: "storefronts",
    },
  ],
};

const actions = {};

const mutations = {};

const getters = {
  getReports(state) {
    return state.reports;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
