<template>
  <v-app style="touch-action: manipulation !important">
    <!-- touch-action: manipulation - disable double tap-to-zoom on mobile devices, keeps pinch/pan gestures -->
    <!-- Nav bar -->
    <v-app-bar app flat dark :color="$vuetify.theme.themes.dark.primary">
      <v-app-bar-nav-icon
        v-if="getUser && getUser.accountType >= 2"
        @click="drawer = !drawer"
      >
        <v-icon>mdi-menu</v-icon>
      </v-app-bar-nav-icon>
      <v-spacer />
      <!-- User icon -->
      <v-menu
        v-if="getUser"
        bottom
        min-width="200px"
        class="rounded-lg"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn icon x-large v-on="on">
            <v-avatar :color="$vuetify.theme.themes.dark.review" size="40">
              <span
                class="text-uppercase font-weight-medium"
                :style="`font-size: 1.15rem;
                    letter-spacing: 0.0125em;
                    
                    color: ${$vuetify.theme.themes.dark.secondary};`"
              >
                {{ getUser.initials }}
              </span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card class="rounded-lg">
          <v-list-item-content class="justify-center pb-0 rounded-lg">
            <div class="mx-auto text-center rounded-lg">
              <v-avatar :color="$vuetify.theme.themes.dark.review" class="my-2">
                <span
                  class="text-uppercase text-h6"
                  :style="`color: ${$vuetify.theme.themes.dark.secondary};`"
                >
                  {{ getUser.initials }}
                </span>
              </v-avatar>
              <h3 class="text-capitalize">
                {{ `${getUser.name.first} ${getUser.name.last}` }}
              </h3>
              <p class="text-caption">
                {{ getUser.email }}
              </p>
              <v-btn
                :disabled="!getUser?.flags?.onboarded"
                depressed
                block
                text
                @click="traverse('profile-view')"
              >
                <v-icon small left>mdi-account</v-icon>
                <span class="text-capitalize">profile</span>
              </v-btn>
              <v-divider class="mt-3"></v-divider>
              <v-btn
                depressed
                block
                text
                class="rounded-b-lg py-6"
                @click="logout"
              >
                <v-icon small left>mdi-logout</v-icon>
                <span class="text-capitalize">log out</span>
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-app-bar>

    <!-- Navigation -->
    <v-navigation-drawer app bottom temporary v-model="drawer">
      <!-- <div class="d-flex justify-center">
        <v-img
          lazy-src="/img/myTrace_fullLogo.png"
          max-height="60"
          max-width="150"
          src="/img/myTrace_fullLogo.png"
          aspect-ratio="1"
        ></v-img>
      </div> -->
      <v-divider></v-divider>
      <!-- Home -->
      <v-list-item dense to="/">
        <v-list-item-icon class="mr-4">
          <v-icon size="20">mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          <span class="text-capitalize font-weight-bold" style="font-size: 14px"
            >home</span
          >
        </v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>
      <!-- User Views -->
      <v-list nav dense>
        <v-list-group
          :color="$vuetify.theme.themes.dark.review"
          v-for="(phase, phaseIndex) in response.views.filter(
            (item) => item.value !== 'reports'
          )"
          :key="phaseIndex"
        >
          <template v-slot:activator>
            <v-list-item-icon class="mr-4">
              <v-icon size="20">{{ phase.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <span
                class="text-uppercase font-weight-bold"
                style="font-size: 14px"
                >{{ phase.text }}</span
              >
            </v-list-item-title>
          </template>
          <v-list-item
            :to="view.route"
            v-for="(view, viewIndex) in phase.views"
            :key="viewIndex"
            class="pr-4 ml-4"
          >
            <v-list-item-icon class="mr-4">
              <v-icon size="20">{{ view.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <span class="text-capitalize text-wrap">{{ view.text }}</span>
            </v-list-item-title>
          </v-list-item>

          <v-divider />
        </v-list-group>
        <v-list-item
          v-for="(phase, phaseIndex) in phaseRoutes"
          :to="phase.route"
          :value="phase.value"
          :key="phase.value"
        >
          <v-list-item-icon class="mr-4">
            <v-icon size="20">{{ phase.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            <span
              class="text-uppercase font-weight-bold"
              style="font-size: 14px"
              >{{ phase.text }}</span
            >
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- Right Drawer -->
    <v-navigation-drawer
      v-model="rightDrawer.active"
      right
      temporary
      app
      bottom
      touchless
      width="500"
      overlay-opacity="0.25"
      class="navDrawer"
      :mobile-breakpoint="600"
      style="z-index: 250 !important"
    >
      <v-sheet style="position: sticky; top: -1px; z-index: 2">
        <v-list-item
          fixed
          v-if="rightDrawer.active"
          :class="
            getRightDrawer.type === 'notifications' ? 'blue darken-1' : ''
          "
        >
          <v-list-item-avatar class="mr-2" tile>
            <v-icon
              :large="!isMobileDevice"
              class="mr-2"
              :color="
                getRightDrawer.type === 'notifications' ? 'white' : undefined
              "
              >{{
                rightDrawer.data?.cartWindow === 0
                  ? "mdi-cart"
                  : "mdi-cart-arrow-right"
              }}</v-icon
            >
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              class="font-weight-bold"
              :class="
                getRightDrawer.type === 'notifications' ? 'white--text' : ''
              "
              >{{ getRightDrawer.title }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-sheet>

      <v-divider />
      <v-container
        fluid
        :class="getRightDrawer.type === 'notifications' ? 'pa-0' : ''"
        style="min-height: calc(100vh - topPadding)"
      >
        <OrderCart
          v-if="getRightDrawer.type === 'cart'"
          :products="getRightDrawer?.data?.products"
          :accountDetails="getRightDrawer?.data?.accountDetails"
          :orderType="getRightDrawer?.data?.orderType"
          :editMode="getRightDrawer?.data?.editCart"
          @cancel-edit="rightDrawer.data.editCart = false"
          @disable-submit-button="
            (val) => (rightDrawer.data.disableSubmission = val)
          "
          @cancel-submit="loading.orderSubmit = false"
          ref="OrderCart"
        />
      </v-container>
      <template v-slot:append v-if="getRightDrawer.type === 'cart'">
        <v-container fluid>
          <v-slide-y-reverse-transition
            leave-absolute
            hide-on-leave
            group
            class="row row--dense d-flex px-0 justify-space-between flex-wrap"
            tag="div"
            style="row-gap: 8px"
          >
            <template
              v-if="
                !getRightDrawer?.data?.editCart &&
                getRightDrawer.data?.products?.length
              "
            >
              <template v-if="getRightDrawer.data?.cartWindow === 0">
                <v-col cols="6" class="py-0" key="editButton">
                  <v-btn
                    color="white"
                    block
                    outlined
                    class="blue--text text--darken-1"
                    @click.native.stop="
                      rightDrawer.active = true;
                      rightDrawer.data.editCart = true;
                    "
                    :disabled="!getRightDrawer?.data?.products?.length"
                  >
                    <v-icon
                      :size="$vuetify.breakpoint.xs ? 14 : 18"
                      class="mr-2"
                      v-text="'mdi-pencil'"
                    />
                    Edit
                  </v-btn>
                </v-col>
                <v-col cols="6" class="py-0" key="checkout">
                  <v-btn
                    color="blue darken-1"
                    :disabled="
                      rightDrawer?.data?.products?.some(
                        (item) => !item.totalQTY
                      )
                    "
                    block
                    class="white--text"
                    @click.native.stop="
                      rightDrawer.data.cartWindow = 1;
                      rightDrawer.title = 'Checkout';
                      $refs.OrderCart.activeViews = {
                        products: null,
                        approvalConfirmation:
                          rightDrawer.data.accountDetails.brandStats.metrics
                            .balance && !$refs.OrderCart.approvalConfirmation,
                      };
                    "
                  >
                    <v-icon
                      :size="$vuetify.breakpoint.xs ? 14 : 18"
                      class="mr-2"
                      v-text="'mdi-cart-arrow-right'"
                    />
                    Checkout
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-0" key="saveDraftButton">
                  <v-btn
                    color="white"
                    block
                    text
                    class="blue--text text--darken-1"
                    :disabled="cartSubmitLoading"
                    @click="rightDrawer.active = false"
                  >
                    Close
                  </v-btn>
                </v-col>
              </template>
              <template v-else>
                <v-col cols="6" class="py-0" key="saveDraftButton">
                  <v-btn
                    color="blue darken-1"
                    block
                    outlined
                    :disabled="cartSubmitLoading"
                    :loading="$refs.OrderCart?.loading?.draft"
                    @click="$refs.OrderCart.saveDraft()"
                  >
                    <v-icon
                      :size="$vuetify.breakpoint.xs ? 14 : 18"
                      class="mr-2"
                      v-text="'mdi-content-save'"
                    />
                    Save Draft
                  </v-btn>
                </v-col>

                <v-col cols="6" class="py-0" key="submit">
                  <v-btn
                    color="green darken-1"
                    :disabled="
                      !getRightDrawer?.data?.products?.every(
                        (item) => item.totalQTY > 0
                      ) ||
                      $refs.OrderCart?.invalidSubmission ||
                      $refs.OrderCart?.loading?.draft
                    "
                    block
                    :loading="cartSubmitLoading"
                    class="white--text"
                    @click="handleOrderSubmit"
                  >
                    <v-icon
                      :size="$vuetify.breakpoint.xs ? 14 : 18"
                      class="mr-2"
                      v-text="'mdi-check-circle'"
                    />
                    Submit
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-0" key="backButton">
                  <v-btn
                    color="white"
                    block
                    text
                    class="blue--text text--darken-1"
                    :disabled="cartSubmitLoading"
                    @click="
                      rightDrawer.data.cartWindow = 0;
                      rightDrawer.title = 'Cart';
                    "
                  >
                    Back
                  </v-btn>
                </v-col>
              </template>
            </template>
            <v-col
              v-else-if="getRightDrawer?.data?.products.length"
              cols="12"
              class="py-0"
              key="button"
            >
              <v-btn
                color="white"
                block
                text
                class="blue--text text--darken-1"
                @click="rightDrawer.data.editCart = false"
              >
                Done
              </v-btn>
            </v-col>
          </v-slide-y-reverse-transition>
        </v-container>
      </template>
    </v-navigation-drawer>

    <!-- App -->
    <v-main
      :style="`background: ${$vuetify.theme.themes.dark.bg}; padding: 64px 0px 0px 0px !important`"
    >
      <router-view />
      <UpdateAlert autoInstall="false" />
    </v-main>
  </v-app>
</template>

<script>
// Libraries
import {
  // Auth
  auth,
  signOut,
  // Firestore
  firestore,
  collection,
  onSnapshot,
  getDocs,
  getDoc,
  doc,
  query,
  where,
} from "@/firebase/init";
import { mapActions, mapGetters } from "vuex";
import * as _ from "lodash";

import OrderCart from "@/components/ui/navigation_drawers/rightDrawer/SalesOrderCart.vue";
import UpdateAlert from "@/components/update/UpdateAlert.vue";

export default {
  name: "App",
  components: {
    UpdateAlert,
    OrderCart,
  },
  data: () => ({
    loading: {
      overall: false,
      orderSubmit: false,
    },
    response: {
      views: [],
    },
    payload: {},
    drawer: false,
  }),
  mounted() {
    document.title = `Home | ${this.getAppName}`;
  },
  created() {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        await this.setup();
      } else {
        // if (this.$router.currentRoute.name !== "login") {
        //   this.$router.replace({
        //     name: "login",
        //   });
        // }
      }
    });
  },
  beforeDestroy() {
    document.title = this.getAppName;
  },
  methods: {
    ...mapActions(["_user"]),
    /* API Calls */
    // GET
    async getUserMetadata() {
      if (auth.currentUser) {
        const userDoc = doc(firestore, "users", auth.currentUser.uid);
        onSnapshot(userDoc, (response) => {
          if (!response.empty) {
            this._user({
              uid: response.id,
              ...response.data(),
              initials:
                auth.currentUser?.displayName
                  ?.split(" ")
                  .map((word) => word.charAt(0))
                  .join("")
                  .toLowerCase() || "",
              accessToken: auth.currentUser.accessToken,
              providers: auth.currentUser.providerData,
            });
          } else {
            // Error
          }
          this.createViews();
        });
      }
    },
    // POST
    async logout() {
      await signOut(auth);
      this._user(null);
      this.$router.replace("/login");
    },
    /* Main */
    async setup() {
      this.loading.overall = true;
      if (auth.currentUser) {
        await this.getUserMetadata();
      }
      this.loading.overall = false;
    },
    async traverse(action, params) {
      switch (action) {
        case "profile-view":
          this.$router.push({
            name: "profile-view",
            params: {
              id: this.getUser.uid,
            },
          });
          break;
      }
    },
    async createViews() {
      let views = [];

      this.response.views = [];

      if (this.getUser?.accountType >= 4) {
        this.response.views = this.getPhases
          .map((phase) => {
            const views = this.getViews.filter(
              (view) => view.phase === phase.phase
            );
            return {
              ...phase,
              views,
            };
          })
          .sort((a, b) => (a.sort > b.sort ? 1 : -1));
      } else {
        if (this.getUser?.views?.length > 0) {
          this.getPhases.forEach((phase) => {
            const phaseViews = this.getViews
              .filter((view) => view.phase === phase.phase)
              .sort((a, b) => (a.sort > b.sort ? 1 : -1));
            const userViews = phaseViews.filter((view) =>
              this.getUser.views.includes(view.value)
            );
            if (userViews.length > 0) {
              views.push({
                ...phase,
                views: userViews,
              });
            }
          });
          if (views.length > 0) {
            views = views.sort((a, b) => (a.sort > b.sort ? 1 : -1));
          }
          this.response.views = views;
        }
      }
    },
    /* Misc */
    async handleOrderSubmit() {
      this.loading.orderSubmit = true;
      await this.$refs.OrderCart.submit();
      this.loading.orderSubmit = false;
    },
  },
  computed: {
    ...mapGetters([
      "getAppName",
      "getCompany",
      "getPhases",
      "getViews",
      "getUser",
      "getRightDrawer",
    ]),
    phaseRoutes() {
      return this.getPhases.filter(
        (phase) =>
          phase.route &&
          (this.getUser?.views.includes(phase.routeValue) ||
            this.getUser?.accountType >= 4)
      );
    },
    rightDrawer: {
      get: function () {
        return this.getRightDrawer;
      },
      set: function (value) {
        this._rightDrawer({
          ...this.getRightDrawer,
          active: value,
        });
      },
    },
    topPadding() {
      return this.isMobileDevice ? "56px" : "64px";
    },
    cartSubmitLoading() {
      return this.$refs.OrderCart?.loading?.submit || this.loading.orderSubmit;
    },
    disableSubmission() {
      return this.$refs.OrderCart?.invalidSubmission;
    },
  },
};
</script>

<style>
.navDrawer.v-navigation-drawer--bottom.v-navigation-drawer--is-mobile {
  max-height: 70% !important;
}
</style>
