import Vue from "vue";
import Vuex from "vuex";
import { colors } from "vuetify/lib";

/* Modules */
// Admin
import Users from "./0_admin/users";
import Tasks from "./0_admin/tasks";
import Products from "./0_admin/products";
// Business
import Accounts from "./1_business/accounts";
import Demos from "./1_business/demos";
import Invoices from "./1_business/invoices";
// Storefronts
import Stores from "./2_storefronts/stores";
// Reports
import Reports from "./3_reports/reports";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    appName: "mytrace",
    company: "mytrace",
    phases: [
      {
        text: "admin",
        value: "admin",
        icon: "mdi-shield-account",
        phase: 0,
        sort: 0,
        vendorLogin: false,
      },
      {
        text: "mytrace",
        value: "business",
        icon: "mdi-card-account-details",
        phase: 1,
        sort: 1,
        vendorLogin: false,
      },
      {
        text: "storefronts",
        value: "storefronts",
        icon: "mdi-storefront",
        phase: 2,
        sort: 2,
        vendorLogin: false,
      },
      {
        text: "trace reports",
        value: "reports",
        icon: "mdi-file-chart",
        sort: 3,
        phase: 3,
        vendorLogin: false,
        route: "/reporting/overview",
        routeText: "Reports",
        routeValue: "reports-overview",
        views: [
          "reports-overview",
          // "storefronts-stores-report",
        ],
      },
    ],
    views: [
      // Admin
      {
        text: "operating zones",
        value: "operatingZones",
        icon: "mdi-earth",
        phase: 0,
        sort: 0,
        route: "/operatingZones/overview",
        views: [
          "operatingZones-overview",
          "country-new",
          "country-view",
          "state-new",
          "state-view",
          // "territory-new",
          // "territory-view",
        ],
      },
      {
        text: "users",
        value: "users",
        icon: "mdi-account-group",
        phase: 0,
        sort: 1,
        route: "/users/overview",
        views: ["users-overview", "user-new", "user-view"],
      },
      {
        text: "tasks",
        value: "tasks",
        icon: "mdi-file-tree",
        phase: 0,
        sort: 2,
        route: "/tasks/overview",
        views: ["tasks", "tasks-new", "tasks-view"],
      },
      {
        text: "products",
        value: "products",
        icon: "mdi-sitemap",
        phase: 0,
        sort: 3,
        route: "/products/overview",
        views: ["products-overview", "product-new", "product-view"],
      },
      // Business
      {
        text: "accounts",
        value: "accounts",
        icon: "mdi-folder-account",
        phase: 1,
        sort: 0,
        route: "/accounts/overview",
        views: ["accounts-overview", "accounts-new", "accounts-view"],
      },
      {
        text: "demos",
        value: "demos",
        icon: "mdi-projector-screen",
        phase: 1,
        sort: 1,
        route: "/demos/overview",
        views: ["demos", "demo-new", "demo-view"],
      },
      {
        text: "invoices",
        value: "invoices",
        icon: "mdi-clipboard-list",
        phase: 1,
        sort: 2,
        route: "/invoices/overview",
        views: ["invoices-overview", "invoices-new", "invoices-view"],
      },
      // Storefronts
      // Companies
      {
        text: "corporate entities",
        value: "corporateEntities-overview",
        icon: "mdi-domain",
        phase: 2,
        sort: 0,
        route: "/corporateEntities/overview",
        views: [
          "corporateEntities-overview",
          "corporateEntities-new",
          "corporateEntities-view",
        ],
      },
      {
        text: "stores",
        value: "stores-overview",
        icon: "mdi-store",
        phase: 2,
        sort: 1,
        route: "/stores/overview",
        views: ["stores-overview", "store-new", "store-view"],
      },
    ],
    departments: [
      { text: "Admin", value: "admin", sort: 1 },
      { text: "IT/Tech", value: "tech", sort: 3 },
      { text: "Accounting/Finances", value: "accounting", sort: 4 },
      { text: "Sales", value: "sales", sort: 8 },
      { text: "Graphic Design/Creative", value: "creative", sort: 9 },
      { text: "Marketing", value: "marketing", sort: 10 },
    ],
    fileUploadTypes: [
      { text: "Link to a URL (larger files)", value: "url" },
      { text: "Upload a file", value: "upload" },
    ],
    contactTitles: [
      {
        text: "Admin",
        value: "admin",
      },
      {
        text: "Owner",
        value: "owner",
      },
      {
        text: "Marketing",
        value: "marketing",
      },
      { text: "Purchasing Manager", value: "purchasing manager" },
      { text: "Store Manager", value: "store manager" },
      { text: "General Manager", value: "general manager" },
      { text: "Inventory Manager", value: "inventory manager" },
      { text: "License Holder", value: "license holder" },
      { text: "Accounting", value: "accounting" },
      { text: "Buyer", value: "buyer" },
      { text: "Sales", value: "sales" },
      { text: "Events", value: "events" },
      { text: "Other", value: "other" },
      { text: "(Blank)", value: null },
    ],
    contactPreferences: [
      { text: "Call", value: "call" },
      { text: "Text (SMS)", value: "text" },
      { text: "Email", value: "email" },
    ],
    phoneNumberTypes: [
      { text: "Mobile", value: "mobile" },
      { text: "Office", value: "office" },
      { text: "Secondary", value: "secondary" },
      { text: "After Hours", value: "afterHours" },
    ],
    user: null,
    rightDrawer: {
      active: false,
    },
    hours: [
      {
        text: "12",
        value: 12,
      },
      {
        text: "1",
        value: 1,
      },
      {
        text: "2",
        value: 2,
      },
      {
        text: "3",
        value: 3,
      },
      {
        text: "4",
        value: 4,
      },
      {
        text: "5",
        value: 5,
      },
      {
        text: "6",
        value: 6,
      },
      {
        text: "7",
        value: 7,
      },
      {
        text: "8",
        value: 8,
      },
      {
        text: "9",
        value: 9,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "11",
        value: 11,
      },
    ],
    minutes: [
      {
        text: "00",
        value: "00",
      },
      {
        text: "15",
        value: "15",
      },
      {
        text: "30",
        value: "30",
      },
      {
        text: "45",
        value: "45",
      },
    ],
    timeOfDay: [
      {
        text: "AM",
        value: "am",
      },
      {
        text: "PM",
        value: "pm",
      },
    ],
  },
  actions: {
    _user({ commit }, payload) {
      commit("setUser", payload);
    },
    _rightDrawer({ commit }, payload) {
      commit("setRightDrawer", payload);
    },
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setRightDrawer(state, payload) {
      state.rightDrawer = payload;
    },
  },
  getters: {
    getAppName(state) {
      return state.appName;
    },
    getCompany(state) {
      return state.company;
    },
    getPhases(state) {
      return state.phases;
    },
    getViews(state) {
      return state.views;
    },
    getDepartments(state) {
      return state.departments;
    },
    getFileUploadTypes(state) {
      return state.fileUploadTypes;
    },
    getContactTitles(state) {
      return state.contactTitles;
    },
    getContactPreferences(state) {
      return state.contactPreferences;
    },
    getPhoneNumberTypes(state) {
      return state.phoneNumberTypes;
    },
    getUser(state) {
      return state.user;
    },
    getRightDrawer(state) {
      return state.rightDrawer;
    },
    getHours(state) {
      return state.hours;
    },
    getMinutes(state) {
      return state.minutes;
    },
    getTimeOfDay(state) {
      return state.timeOfDay;
    },
  },
  modules: {
    Users,
    Tasks,
    Products,
    Accounts,
    Demos,
    Invoices,
    Stores,
    Reports,
  },
});
