const state = {
  accountTypes: [
    { text: "admin", value: 4 },
    { text: "manager", value: 3 },
    { text: "user", value: 2 },
    { text: "third party", value: 1 },
  ],
  userRoles: [
    { text: "Account Manager", value: "accountManager" },
    { text: "Account Executive", value: "accountExecutive" },
    { text: "Accounting", value: "accounting" },
    { text: "Developer", value: "developer" },
    { text: "Trainer", value: "trainer" },
  ],
  userPresets: [
    {
      text: "Accounting",
      value: "accounting",
      desc: "Apply payments to invoices",
      department: "accounting",
      accountType: 1,
      role: "accounting",
      accountType: null,
      dashboards: [],
      views: ["tasks", "accounts", "invoices", "reports-overview"],
    },
    {
      text: "Trainer",
      value: "trainer",
      desc: "Demos mytrace to prospective leads",
      department: "admin",
      accountType: 1,
      role: "trainer",
      dashboards: [],
      views: ["tasks", "accounts", "invoices"],
    },
    {
      text: "Developer",
      value: "developer",
      desc: "Manages development requests for mytrace instance customization",
      department: "tech",
      accountType: 1,
      role: "developer",
      dashboards: [],
      views: ["tasks", "accounts", "invoices"],
    },
  ],
  dashboards: ["buyer", "client"],
  emailPreferenceOptions: [
    { text: "Order Related Emails", value: "orderRelated" },
    { text: "Marketing Related Emails", value: "marketingRelated" },
  ],
};

const actions = {};

const mutations = {};

const getters = {
  getAccountTypes(state) {
    return state.accountTypes;
  },
  getUserRoles(state) {
    return state.userRoles;
  },
  getUserPresets(state) {
    return state.userPresets;
  },
  getUserDashboards(state) {
    return state.dashboards;
  },
  getEmailPreferenceOptions(state) {
    return state.emailPreferenceOptions;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
