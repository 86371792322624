import colors from "vuetify/lib/util/colors";

const state = {
  storeStatus: [
    {
      text: "Cold Lead",
      value: "lead_cold",
      active: false,
      create: true,
      canOrder: true,
      managerApproval: false,
      desc: "No prior contact, individual who could approve a sample order has not been identified.",
      accountType: 2,
      color: colors.grey.base,
    },
    {
      text: "Hot Lead",
      value: "lead_hot",
      active: false,
      create: true,
      canOrder: true,
      managerApproval: false,
      desc: "A contact with the ability to approve a sample order has been made. These accounts should take higher priority > cold leads.",
      accountType: 2,
      color: colors.orange.base,
    },
    {
      text: "On Hold - Lead",
      value: "lead_onHold",
      active: true,
      canOrder: false,
      desc: "This lead is on hold due to management changes/license issues/etc. The status will need to be updated back to a cold/hot lead to create orders.",
      accountType: 2,
      color: colors.red.darken3,
    },
    {
      text: "Samples - Sent",
      value: "samples_sent",
      create: true,
      active: false,
      canOrder: true,
      desc: `A *Lead status account has had a sample order submitted. Once delivered the status will auto-update to "Samples - Received".`,
      managerApproval: false,
      accountType: 2,
      color: colors.yellow.darken1,
    },
    {
      text: "Samples - Received",
      value: "samples_received",
      create: true,
      active: false,
      canOrder: true,
      desc: `This account has received their sample order. A new task 2+ weeks in the future has been assigned to the rep managing the account to follow up.`,
      managerApproval: false,
      accountType: 2,
      color: colors.lightBlue.base,
    },
    {
      text: "Active - Current payments",
      value: "active_current",
      active: true,
      create: true,
      canOrder: true,
      desc: "Account has been converted from a *Lead to an active account. Payments are on time & accounts receivable is minimal.",
      managerApproval: false,
      accountType: 2,
      color: colors.green.base,
    },
    {
      text: "Active - Orders Under Hub Account",
      value: "active_ordersUnderHubAccount",
      active: true,
      create: true,
      canOrder: false,
      desc: "This account is active, but orders are submitted on a different 'Hub' style Account. Orders should not be placed on this account.",
      managerApproval: false,
      accountType: 2,
      color: colors.green.accent3,
    },
    {
      text: "Active - Delinquent payments",
      value: "active_delinquent",
      active: true,
      canOrder: true,
      desc: "Account is actively ordering, but has 1+ open invoice(s) outside of its terms.",
      managerApproval: true,
      accountType: 2,
      color: colors.red.lighten3,
    },
    {
      text: "Payment Plan - Actively Ordering",
      value: "paymentPlan_active",
      active: true,
      canOrder: true,
      managerApproval: true,
      desc: "Account is on a payment plan & actively ordering.",
      accountType: 2,
      color: colors.purple.base,
    },
    {
      text: "Payment Plan - No Orders",
      value: "paymentPlan_inactive",
      active: true,
      canOrder: false,
      desc: "Account is on a payment plan & cannot place any new orders.",
      managerApproval: true,
      accountType: 3,
      color: colors.yellow.base,
    },
    {
      text: "Credit Hold",
      value: "credit_hold",
      active: true,
      canOrder: false,
      desc: "Account is not on a payment plan & cannot place any new orders.",
      managerApproval: true,
      accountType: 3,
      color: colors.blueGrey.base,
    },
    {
      text: "Out of business - Client",
      value: "oob_client",
      active: false,
      canOrder: false,
      desc: "Account has closed doors, and is no longer in business. Previously was an account.",
      accountType: 3,
      color: colors.shades.black,
    },
    {
      text: "Out of business - Not a client",
      value: "oob_notclient",
      active: false,
      canOrder: false,
      desc: "Account has closed doors and was never converted to being an account.",
      accountType: 3,
      color: colors.shades.black,
    },
    {
      text: "DO NOT CALL 💀",
      value: "do_not_call",
      active: false,
      canOrder: false,
      desc: "This account has requested or we have identified not to do business with them due to unpaid balances/etc.",
      accountType: 3,
      color: colors.red.base,
    },
  ],
  orderStatus: [
    {
      text: "Cancelled",
      value: "cancelled",
      color: colors.red.base,
      report: true,
      delivered: false,
      sort: -3,
    },
    {
      text: "Rejected",
      value: "rejected",
      color: colors.red.base,
      report: true,
      delivered: false,
      sort: -2,
    },
    {
      text: "Awaiting Approval",
      value: "draft",
      color: colors.grey.base,
      report: true,
      delivered: false,
      sort: -1,
    },
    {
      text: "Submitted",
      value: "submitted",
      color: colors.shades.black,
      report: true,
      delivered: false,
      sort: 0,
    },
    {
      text: "Accepted",
      value: "accepted",
      color: colors.blue.base,
      report: true,
      delivered: false,
      sort: 1,
    },
    {
      text: "Delivery Confirmation",
      value: "deliveryConfirmation",
      color: colors.blue.darken3,
      report: true,
      delivered: false,
      sort: 2,
    },
    {
      text: "Fulfilled",
      value: "fulfilled",
      color: colors.brown.base,
      report: true,
      delivered: false,
      sort: 3,
    },
    {
      text: "Shipped",
      value: "shipped",
      color: colors.purple.base,
      report: true,
      delivered: true,
      sort: 4,
      ar: true,
    },
    {
      text: "Complete",
      value: "complete",
      color: colors.green.base,
      report: true,
      delivered: true,
      sort: 5,
      ar: true,
    },
  ],
};

const actions = {};

const mutations = {};

const getters = {
  getStoreStatus(state) {
    return state.storeStatus;
  },
  getOrderStatus(state) {
    return state.orderStatus;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
