<template>
  <div class="d-inline justify-center align-center">
    <span class="d-flex justify-center align-center" style="position: relative">
      <span
        class="d-flex align-center justify-center pa-0 font-weight-bold"
        :style="`
          width: ${size}px;
          height: ${size}px;
          color: white;
          border-radius: 50%;
          position: absolute;
          line-height: ${size}px;
          right: -20px;`"
      >
        <v-icon color="grey" :style="`font-size: ${iconSize}px`">
          {{ iconText }}
        </v-icon>
      </span>
      <slot />
    </span>
  </div>
</template>

<script>
export default {
  data: () => ({}),
  props: {
    size: {
      type: Number,
      default: 16,
    },
    iconSize: {
      type: Number,
      default: 12,
    },
    iconText: {
      type: String,
      default: "mdi-pencil",
    },
    inProgress: {
      type: Boolean,
      default: true,
    },
  },
  methods: {},
  computed: {},
};
</script>
