<template>
  <div>
    <v-text-field
      :label="label"
      clearable
      @click:clear="clearInput"
      :background-color="backgroundColor"
      prepend-inner-icon="mdi-magnify"
      :color="$vuetify.theme.themes.dark.primary"
      v-model.trim="search"
      :disabled="disableField"
      @input="handleSearch"
      hide-details
    >
      <template v-slot:append-outer>
        <v-btn
          v-if="selection && addressUpdated"
          small
          rounded
          dark
          @click="traverse('reset', 'address')"
          tabindex="-1"
        >
          <span class="mr-1">undo</span>
          <v-icon small>{{ $vuetify.icons.values.undo }}</v-icon>
        </v-btn>
      </template>
    </v-text-field>

    <v-list
      dense
      v-if="response.searchResults.length > 0 && !loading.results"
      class="py-0 transparent"
    >
      <template v-for="(result, i) in response.searchResults">
        <v-divider v-if="i !== 0" :key="`${i}-divider`"></v-divider>

        <v-list-item :key="`${i}-${result.text}`">
          <v-list-item-icon class="mr-4">
            <v-icon small>
              {{ $vuetify.icons.values.map }}
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="text-wrap">{{
              result.text
            }}</v-list-item-title>
          </v-list-item-content>

          <v-list-item-icon>
            <v-btn
              xSmall
              rounded
              :style="`background: ${
                result.selected
                  ? $vuetify.theme.themes.dark.success
                  : $vuetify.theme.themes.dark.review
              }; color: ${$vuetify.theme.themes.dark.secondary};`"
              @click="traverse('select', result)"
            >
              <span>{{ result.selected ? "selected" : "select" }}</span>
              <v-icon xSmall class="pl-1">{{
                result.selected
                  ? $vuetify.icons.values.submit
                  : "mdi-cursor-default"
              }}</v-icon>
            </v-btn>
          </v-list-item-icon>
        </v-list-item>
      </template>
    </v-list>
    <v-list dense v-else class="transparent">
      <v-list-item key="noData" class="d-flex justify-center align-center">
        <div class="d-flex justify-center" v-if="loading.results">
          <div class="d-flex flex-row align-center justify-center">
            <v-progress-circular
              :width="3"
              size="15"
              :color="$vuetify.theme.themes.dark.primary"
              indeterminate
            ></v-progress-circular>
            <v-list-item-subtitle class="text--disabled ml-2"
              >Loading results...</v-list-item-subtitle
            >
          </div>
        </div>
        <v-list-item-content v-else>
          <v-list-item-subtitle
            v-if="!search || search?.length === 0"
            class="text--disabled d-flex justify-center"
            >Enter an address to search</v-list-item-subtitle
          >
          <v-list-item-subtitle
            v-else
            class="text--disabled d-flex justify-center"
            >No results found.</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
// Libraries
import { mapGetters } from "vuex";
import gmapsInit from "@/utils/gmaps";
import _ from "lodash";
// Components

export default {
  name: "places-search",
  props: [
    "placeholderText",
    "types",
    "label",
    "disableField",
    "address",
    "prefilledAddress",
    "required",
  ],
  data: () => ({
    loading: {
      overall: false,
      results: false,
    },
    response: {
      headers: {
        locations: [
          { text: "Number", value: "number" },
          { text: "Icon", value: "icon" },
          { text: "Address", value: "text" },
          { text: "Select", value: "action" },
        ],
      },
      service: null,
      searchResults: [],
    },
    search: "",
    selection: null,
    initialAddress: null,
  }),
  created() {
    this.initialAddress = this.address.replace(/\b\w/g, (l) => l.toUpperCase());
  },
  async mounted() {
    try {
      const google = await gmapsInit();
      this.response.service = new google.maps.places.AutocompleteService();
    } catch (error) {
      console.log(error);
      return error;
    }

    if (this.address?.length > 0) {
      this.search = this.address.replace(/\b\w/g, (l) => l.toUpperCase());
      this.response.service.getPlacePredictions(
        {
          input: this.search,
          types: [this.types],
          componentRestrictions: { country: "us" },
        },
        this.displaySuggestions
      );
    }
  },
  methods: {
    /* API Calls */
    // GET
    displaySuggestions(predictions, status) {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.response.searchResults = [];
        this.loading.results = false;
        return;
      }
      this.response.searchResults = predictions.map((prediction) => {
        return {
          selected:
            prediction.description.replace(", USA", "").toLowerCase() ===
            this.search.replace(", USA", "").toLowerCase(),
          text: prediction.description,
          value: prediction.place_id,
        };
      });

      if (this.response.searchResults.find((item) => item.selected)) {
        this.selection = this.response.searchResults.find(
          (item) => item.selected
        );
      }

      const selected = this.selectedLocation[0];

      if (selected) {
        this.$emit("place-search", selected);
      }

      this.loading.results = false;
    },
    // POST
    /* Main */
    async traverse(action, params) {
      switch (action) {
        case "select":
          this.response.searchResults.forEach(
            (prediction) => (prediction.selected = false)
          );
          this.response.searchResults.find(
            (prediction) => prediction.value === params.value
          ).selected = true;
          const selected = this.selectedLocation[0];
          this.search = selected.text;
          this.selection = selected;

          this.response.service.getPlacePredictions(
            {
              input: this.search,
              types: [this.types],
              componentRestrictions: { country: "us" },
            },
            this.displaySuggestions
          );

          this.$emit("place-search", selected);
          break;
        case "reset":
          switch (params) {
            case "address":
              this.search = this.initialAddress;
              await this.response.service.getPlacePredictions(
                {
                  input: this.search,
                  types: [this.types],
                  componentRestrictions: { country: "us" },
                },
                this.displaySuggestions
              );

              const selected = this.selectedLocation[0];
              this.$emit("place-search", selected);
              break;
          }
          break;
        case "undo":
          this.response.searchResults.forEach(
            (prediction) => (prediction.selected = false)
          );
          this.$emit("place-search", null);
          break;
      }
    },
    clearInput() {
      this.search = "";
      this.selection = null;
      this.$emit("place-search", null);
    },
    handleSearch() {
      this.loading.results = true;
      if (this.search?.length > 0) {
        this.getPredictions();
      } else {
        this.$emit("place-search", null);
        this.selection = null;
        this.response.searchResults = [];
        this.loading.results = false;
      }
    },
    getPredictions: _.debounce(function () {
      this.response.service.getPlacePredictions(
        {
          input: this.search,
          types: [this.types],
          componentRestrictions: { country: "us" },
        },
        this.displaySuggestions
      );
    }, 500),
    /* Misc */
  },
  computed: {
    ...mapGetters([]),
    backgroundColor() {
      if (!this.disableField && this.required) {
        return !this.selection
          ? "yellow lighten-3"
          : this.addressUpdated
          ? "orange lighten-3"
          : "";
      } else {
        return this.addressUpdated ? "orange lighten-3" : "";
      }
    },
    resultsExist() {
      return this.response.searchResults.length > 0 ? true : false;
    },
    selectedLocation() {
      return this.resultsExist
        ? this.response.searchResults.filter(
            (prediction) => prediction.selected
          )
        : [];
    },
    oneLocationSelected() {
      return this.selectedLocation.length > 0 ? true : false;
    },
    addressUpdated() {
      if (this.initialAddress?.length > 0) {
        return (
          this.selection &&
          this.initialAddress.replace(", USA", "").toLowerCase() !==
            this.selection?.text?.replace(", USA", "").toLowerCase()
        );
      }
      return false;
    },
  },
};
</script>

<style></style>
