const state = {
  // products: [
  //   {
  //     name: "MyTrace",
  //     value: "mytrace",
  //     cost: 4995,
  //     users: true,
  //     development: true,
  //   },
  //   {
  //     name: "Product Validator",
  //     value: "validator",
  //     cost: 2500,
  //     users: false,
  //     development: true,
  //   },
  // ],
  // addons: [
  //   {
  //     name: "Users",
  //     value: "users",
  //     cost: 50,
  //   },
  //   {
  //     name: "Development/Customization",
  //     value: "development",
  //     cost: 5000,
  //   },
  // ],
  strainTypes: [
    {
      text: "Sativa",
      value: "sativa",
    },
    {
      text: "Indica",
      value: "indica",
    },
    {
      text: "Hybrid",
      value: "hybrid",
    },
    {
      text: "Not Set",
      value: null,
    },
  ],
};

const actions = {};

const mutations = {};

const getters = {
  // getProducts(state) {
  //   return state.products;
  // },
  // getAddons(state) {
  //   return state.addons;
  // },
  getStrainTypes(state) {
    return state.strainTypes;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
